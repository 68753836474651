import styled from 'styled-components'

export const Section = styled.div`
    margin: 20px 60px;
`

export const FlexDiv = styled.div`
    display: flex;
    margin-bottom: 10px;
`

export const SearchBar = styled.div`
    flex-grow: 1;
    margin: 15px;
`

export const FlexDivLabel = styled.div`
    margin-right: 10px;
    width: 160px;
`

export const FlexDivLabelSub = styled.div`
    font-size: 0.6em;
`

export const TextList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const ProjectInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const ProjectLabel = styled.label`
    display: inline-block;
    color: #555555;
    font-size: .8em;
    border-radius: 3px;
    justify-content: space-around;
    width: auto;
    margin-right: 5px;
    margin-top: 3px;
    margin-bottom: 3px;
    margin-left: 200px;
    vertical-align: text-top;
`

export const Progress = styled.div`
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    margin-bottom: 2em;
    width: 8em;
`

export const StatusMessage = styled.div`
    width: 8em;
    color: gray;
    position: right;
    display: inline-block;
    margin-top: 1em;
    text-align: center;
    letter-spacing: 1px;
    margin-left: 1.7em;
`

export const Input = styled.input.attrs(props => ({
    type: 'text',
}))`
    padding-left: 25px;
    position: absolute;
    height: 100%;
    width: 100%;
    color: #555555;
    outline: none;
    font-size: 16px;
    border: 1px solid #555555;
    verical-alignment: top;
    overflow: wrap;

    ::placeholder {
        color: gray;
        position: top;
    }
`

export const ButtonsList = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
`

export const ButtonsListFloat = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    background: grey;
    margin-top: 0.5em;
`

export const Button = styled.button`
    color: #555555;
    font-size: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
    border: 1px solid #555555;
    padding: 0.25em 1em;
    border-radius: 3px;
    display: #555555;
    background: white;
    text-decoration-line:none;
`

export const ReturnButton = styled.a`
    display: inline-block;
    color: #555555;
    font-size: 0.6em;
    padding: 4px;
    margin: 4px 12px;
    border: none;
    border-radius: 3px;
    display: #555555;
    background: white;
    text-decoration-line: none;
`

export const DocRendering = styled.div`
    display: inline-block;
    color: #555555;
    font-size: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
    padding: 0.25em 1em;
    white-space: pre-line;
    text-align: left;
`

export const JDSection = styled.div`
    color: #555555;
    font-size: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
    padding: 0.25em 1em;
    white-space: pre-line;
    text-align: center;
`

export const ErrorMsg = styled.div`
    color: red;
`

export const SuccessMsg = styled.div`
    color: green;

`

export const FormLabel = styled.label`
    width: 13em;
`

export const JobDescriptionFields = styled.div`
    margin-left: 22%;
    width: 45em;
`

export const ListFilterElement = styled.label`
    display: inline-block;
    color: #555555;
    font-size: .62em;
    border-radius: 3px;
    justify-content: space-around;
    width: auto;
    margin-right: 5px;
    margin-top: 7px;
    margin-bottom: 7px;
    vertical-align: text-top;

`

export const FilterInput = styled.input`
    display: inline-block;
    color: #555555;
    margin-bottom: .5em;
    margin-right: 0em;
    border: 2px solid #555555;
    border-radius: 3px;
    width: 40px;
    display: #555555;
    margin-top: 7px;
    margin-bottom: 7px;
    vertical-align: top;
`


export const IndFilterBox = styled.div`
    display: inline-block;
    color: #555555;
    font-size: 1em;
    margin: 1em;
    padding: 0.25em 0.25em;
    border-radius: 3px;
    display: #555555;
    background: white;
    flex-direction: column;
    vertical-align: top;
    width: 59.5%;
    border: 1px solid #555555;
`

export const InterviewerList = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 10px;
`

export const EmailSearch = styled.input`
    margin-left: 10%;
    width: 90em;
`

export const LoadingDiv = styled.div`
    text-align: center;
    width: 100%;
    font-size: 2em;
    color: lightgrey;
`
export const JobDescPage = styled.div`
    margin: 16px 48px;
    padding: 36px;
    background: white;
    border: 1px solid lightgrey;
    font-family: 'Garamond', 'Times New Roman';
`
export const JobDescTitle = styled.div`
    margin: 48px 16px 16px;
    font-size: 1.8em;
    font-weight: bold;
`

export const JobDescHeader = styled.div`
    font-size: 1.4em;
    font-weight: bold;
`

export const JobDescContent = styled.div`
    margin: 16px;
    white-space: pre-wrap;
    font-size: 1.1em;
`

export const JobDescText = styled.textarea`
    border: 1px dotted lightgrey;
    width: 100%;
    margin-bottom: 24px;
    min-height: 120px;
`

export const JobDescTextSmall = styled.textarea`
    border: 1px dotted lightgrey;
    width: 100%;
    margin-bottom: 24px;
    min-height: 80px;
`

export const JobDescPageBreak = styled.div`
    border-width: 1px 0 0 0;
    border-style: dashed;
    border-color: lightgrey;
    margin: 48px -36px;
    text-align: center;
    overflow: visible;
    height: 0;
    line-height: 0;
    font-size: 0.8em;
    text-shadow: 3px 0 0 white, 0 3px 0 white, -3px 0 0 white, 0 -3px 0 white;
    color: lightgrey;
`

export const JobDescContentExit = styled.div`
    margin: 48px 16px;
    white-space: pre-wrap;
    font-size: 1.1em;
    min-height: 240px;
`
