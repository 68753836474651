const primary_main = '#df1683'
const secondary_main = '#00000'

const theme = {
  palette: {
    primary: {
      main: primary_main
    },
    secondary: {
      main: secondary_main
    }
  },
  components: {
    // MuiButton: {
    //   // TODO: style override is temporary until current styles are cleaned up. right now, current styles break MUI
    //   styleOverrides: {
    //     root: ({ ownerState }) => ({
    //       ...(ownerState.color === 'secondary' && {
    //         color: '#000 !important',
    //       }),
    //     }),
    //   }
    // },
    // MuiInputLabel: {
    //   // TODO: style override is temporary until current styles are cleaned up. right now, current styles break MUI
    //   styleOverrides: {
    //     root: {
    //       marginTop: 0,
    //       marginBottom: 0,
    //     },
    //   },
    // },
  },
}

export default theme
