import React, { useReducer, useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { fetchWithToken } from '../../utils/fetchWithToken'
import * as sc from '../StyledComponents'
import Auto from '../Auto'
import RoleDefaultValues from './RoleDefaultValues.json'

const FormReducer = (state, event) => ({
    ...state,
    ...event
})

export default function RoleForm(props) {
    const role_id = props.match.params.id
    const role_load_id = props.match.params.copy_id || role_id

    const [formData, setFormData] = useReducer(FormReducer, RoleDefaultValues);

    const [loading, setLoading] = useState(true);

    const [submitting, setSubmitting] = useState(false)

    const [contentJDIntro, setContentJDIntro] = useState(true)
    const [contentJDContact, setContentJDContact] = useState(true)

    const [groups, setGroups] = useState([])

    const [selectBillableTarget, setSelectBillableTarget] = useState([
        "0%",
        "25%",
        "50%",
        "75%",
        "100%"
    ])

    const [selectCareerLevels, setSelectCareerLevels] = useState([
        "Generalist - 1 - Associate ($30,000 - $70,000)",
        "Generalist - 2 - Senior Associate ($50,000 - $90,000)",
        "Generalist - 3 - Consultant ($70,000 - $120,000)",
        "Generalist - 4 - Senior Consultant ($80,000 - $130,000)",
        "Management - 5 - Manager ($90,000 - $150,000)",
        "Management - 6 - Senior Manager ($100,000 - $160,000)",
        "Management - 7 - Director ($120,000 - $180,000)",
        "Management - 8 - Managing Director ($140,000 - $200,000)",
        "Specialist - 5 - Specialist ($90,000 - $150,000)",
        "Specialist - 6 - Senior Specialist ($100,000 - $160,000)",
        "Other"
    ])

    const [selectSalaryRange, setSelectSalaryRange] = useState([
        "$60,000-$80,000",
        "$80,000-$100,000",
        "$100,000-$120,000"
    ])

    const [selectHardwareRequirement, setSelectHardwareRequirement] = useState([
        "Laptop - Data Analyst - 16GB",
        "Laptop - Developer - 16GB",
        "Laptop - PM/Admin/BA - 8GB",
        "Machine not needed"
    ])

    const [selectO365License, setSelectO365License] = useState([
        "Email Address Only (E1)",
        "Office Applications + Email (E3)"
    ])

    const [selectO365Tools, setSelectO365Tools] = useState([
        "Skype Basic",
        "Skype Conferencing (Video/Phone)",
        "Teams",
        "Visio",
        "PowerBI",
        "Project"
    ])

    const [selectDistributionList, setSelectDistributionList] = useState([
        "LCG-ALL",
        "LCG-Bellevue",
        "LCG-Roseville",
        "LCG-Texas"
    ])

    const [selectStudioOptions, setSelectStudioOptions] = useState([])

    const [selectStudio, setSelectStudio] = useState([])
    const [selectStudioDiscipline, setSelectStudioDiscipline] = useState([])

    useEffect(() => {
        const effect = async () => {
            let promises = []

            let loaders = {
                "billable-target": [setSelectBillableTarget, (value) => setFormData({ BillableTarget: value })],
                "career-levels": [setSelectCareerLevels, (value) => setFormData({ CareerLevels: value })],
                "salary-range": [setSelectSalaryRange, (value) => setFormData({ SalaryRange: value })],
                "hardware-requirement": [setSelectHardwareRequirement, (value) => setFormData({ HardwareRequirements: value })],
                "office365-license": [setSelectO365License, (value) => setFormData({ O365License: value })],
                "office365-tools": [setSelectO365Tools],
                "distribution-lists": [setSelectDistributionList],
            }

            for (const key in loaders) {
                promises.push(
                    fetchWithToken(process.env.REACT_APP_ENDPOINT + `/api/form-setting/role/${key}`)
                    .then((response) => {
                        const [field_loader, field_default] = loaders[key]
                        field_loader(response.data.result.options)

                        if (field_default) {
                            field_default(response.data.result.default_option)
                        }
                    })
                    .catch(e => console.error(e))
                )
            }

            promises.push(
                fetchWithToken(process.env.REACT_APP_ENDPOINT + `/api/form-setting/role/studios`)
                .then((response) => {
                    setSelectStudioOptions(response.data.result.categories)
                    setSelectStudio(response.data.result.categories.map((category) => category.name))
                })
                .catch(e => console.error(e))
            )

            await Promise.all(promises)

            await fetchWithToken(process.env.REACT_APP_ENDPOINT + `/api/AppUserGroup`)
                .then((response) => setGroups(response.data))
                .catch(e => console.error(e))

            await Promise.all([
                fetchWithToken(process.env.REACT_APP_ENDPOINT + '/api/form-setting/content/job-description-introduction'),
                fetchWithToken(process.env.REACT_APP_ENDPOINT + '/api/form-setting/content/job-description-contact-statement')
            ])
            .then(([jdIntroResult, jdContactStateResult]) => {
                setContentJDIntro(jdIntroResult.data.result.content)
                setContentJDContact(jdContactStateResult.data.result.content)
            });

            if (!role_load_id) {
                setLoading(false)
                return
            }

            await fetchWithToken(process.env.REACT_APP_ENDPOINT + `/api/roles/${role_load_id}`)
                .then((result) => {
                    console.log('Role Loaded', result.data.result)
                    setFormData(result.data.result)
                }).catch(err => {
                    console.error(err)
                })

            setLoading(false)
        }

        effect()

    // We need this to run once and just once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!formData.FunctionalArea) {
            return
        }

        const category = selectStudioOptions.find((category) => category.name === formData.FunctionalArea)
        setSelectStudioDiscipline(category ? category.options || [] : [])
    }, [selectStudioOptions, formData.FunctionalArea])

    const handleSubmit = useCallback(event => {
        event.preventDefault();
        setSubmitting(true);

        const url = `${process.env.REACT_APP_ENDPOINT}/api/roles/${role_id}`
        let options = {
            method: role_id ? 'PUT' : 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: formData
        }

        console.log('Role POST', formData, JSON.stringify(formData))

        fetchWithToken(url, options)
            .then(function () {
                setSubmitting(false)
                window.location = "/roles";
            })
    }, [role_id, formData])

    const handleLimitedChange = useCallback((limit) => event => {
        setFormData({
            [event.target.name]: String(event.target.value).substring(0, limit)
        })
    }, [])

    const handleChange = useCallback(event => {
        setFormData({
            [event.target.name]: event.target.value
        })
    }, [])

    const childChangeHandler = useCallback((parent, name, value) => {
        let obj = formData[parent];
        obj[name] = value;
        setFormData({
            [parent]: obj
        })
    }, [formData])

    const handleDistributionCheckbox = useCallback(event => {
        // Add checkbox value to formData when checked
        if (event.target.checked) {
            const newArray = [...formData.DistributionLists, event.target.name]
            setFormData({
                DistributionLists: newArray
            })
        } else {
            // Remove checkbox value from formData when unchecked
            const newArray = formData.DistributionLists.filter(x => x !== event.target.name);
            setFormData({
                DistributionLists: newArray
            })
        }
    }, [formData.DistributionLists])

    const handle0365ToolsCheckbox = useCallback(event => {
        // Add checkbox value to formData when checked
        if (event.target.checked) {
            const newArray = [...formData.O365Tools, event.target.name]
            setFormData({
                O365Tools: newArray
            })
        } else {
            // Remove checkbox value from formData when unchecked
            const newArray = formData.O365Tools.filter(x => x !== event.target.name);
            setFormData({
                O365Tools: newArray
            })
        }
    }, [formData.O365Tools])

    const handleAddRoleInterviewer = useCallback((value) => {
        if (!value) {
            return
        }

        setFormData(
            {
                RoleInterviewers: [...formData.RoleInterviewers, `${value.id}/${value.label}`]
            }
        )
    }, [formData.RoleInterviewers])

    const handleRemoveRoleInterviewer = useCallback((e) => {
        e.preventDefault();
        const myInterviewers = [...formData.RoleInterviewers]
        const index = myInterviewers.indexOf(e.target.name);
        myInterviewers.splice(index, 1);
        setFormData(
            {
                RoleInterviewers: myInterviewers
            }
        )
    }, [formData.RoleInterviewers])

    const handleSetOwner = useCallback((value) => {
        setFormData({ ownerUserEmail: value.id })
    }, [])

    return <>
        <sc.Section style={{ "margin": "0 8em 0 8em", "paddingTop": 0 }}>
            <sc.ButtonsList>
                <sc.ReturnButton as="a" href="/roles">&lt; Return to Roles</sc.ReturnButton>
            </sc.ButtonsList>

            { loading ? <sc.LoadingDiv>Loading Content</sc.LoadingDiv> : <div>
            <form className="RoleForm" onSubmit={(e) => e.preventDefault()}>
                <sc.FlexDiv>
                    <sc.FlexDivLabel>Owner</sc.FlexDivLabel>
                    <Auto add={handleSetOwner} valued value={formData.ownerUserEmail} />
                </sc.FlexDiv>
                <sc.FlexDiv>
                    <sc.FlexDivLabel>Owning Group</sc.FlexDivLabel>
                    <select name="ownerGroupId" value={formData.ownerGroupId} onChange={handleChange}>
                        <option default></option>
                        {groups.map((value) => <option value={value.id} key={value.id}>{value.name}</option>)}
                    </select>
                </sc.FlexDiv>
                <hr />
                <sc.FlexDiv>
                    <sc.FlexDivLabel>Projector Role ID</sc.FlexDivLabel>
                    <input name="RoleID" type="text" value={formData.RoleID} placeholder="Enter Projector Role ID Here..." onChange={handleChange}></input>
                </sc.FlexDiv>
                <sc.FlexDiv>
                <sc.FlexDivLabel>Title</sc.FlexDivLabel>
                <input name="RoleTitle" type="text" value={formData.RoleTitle} placeholder="Enter Title Here..." onChange={handleChange}></input>
                </sc.FlexDiv>
                <sc.FlexDiv>
                <sc.FlexDivLabel>HRIS Title</sc.FlexDivLabel>
                <input name="HRISTitle" type="text" value={formData.HRISTitle} placeholder="Enter HRIS Title Here..." onChange={handleLimitedChange(36)}></input>
                </sc.FlexDiv>
                <sc.FlexDiv>
                <sc.FlexDivLabel>Studio</sc.FlexDivLabel>
                <select name="FunctionalArea" value={formData.FunctionalArea} onChange={handleChange}>
                    <option default>Select Studio</option>
                    {selectStudio.map((value) =>
                        <option value={value} key={value}>{value}</option>
                    )}
                </select>
                </sc.FlexDiv>
                <sc.FlexDiv>
                <sc.FlexDivLabel>Discipline</sc.FlexDivLabel>
                <select name="Discipline" value={formData.Discipline} onChange={handleChange}>
                    <option default>Select Discipline</option>
                    {selectStudioDiscipline.map((value) =>
                        <option value={value} key={value}>{value}</option>
                    )}
                </select>
                </sc.FlexDiv>
                <sc.FlexDiv>
                <sc.FlexDivLabel>Career Level</sc.FlexDivLabel>
                <select name="CareerLevels" value={formData.CareerLevels} onChange={handleChange}>
                    <option default>Select Career Level</option>
                    {selectCareerLevels.map((value) =>
                        <option value={value} key={value}>{value}</option>
                    )}
                </select>
                </sc.FlexDiv>
                <sc.FlexDiv>
                <sc.FlexDivLabel>Salary Range</sc.FlexDivLabel>
                <select name="SalaryRange" value={formData.SalaryRange} onChange={handleChange}>
                    <option default>Select Salary Range</option>
                    {selectSalaryRange.map((value) =>
                        <option value={value} key={value}>{value}</option>
                    )}
                </select>
                </sc.FlexDiv>
                <sc.FlexDiv>
                <sc.FlexDivLabel>Billable Target</sc.FlexDivLabel>
                <select name="BillableTarget" value={formData.BillableTarget} onChange={handleChange}>
                    <option default>Select Billable Target </option>
                    {selectBillableTarget.map((value) =>
                        <option value={value} key={value}>{value}</option>
                    )}
                </select>
                </sc.FlexDiv>
                <sc.FlexDiv>
                <sc.FlexDivLabel>Hardware Requirements</sc.FlexDivLabel>
                <select name="HardwareRequirements" value={formData.HardwareRequirements} onChange={handleChange}>
                    <option default>Select Hardware Requirements </option>
                    {selectHardwareRequirement.map((value) =>
                        <option value={value} key={value}>{value}</option>
                    )}
                </select>
                </sc.FlexDiv>
                <hr />
                <h5>Office 365</h5>
                <sc.FlexDiv>
                    <sc.FlexDivLabel>Office License</sc.FlexDivLabel>
                    <select name="O365License" value={formData.O365License} onChange={handleChange}>
                        <option default>Select O365 License </option>
                        {selectO365License.map((value) =>
                            <option value={value} key={value}>{value}</option>
                        )}
                    </select>
                </sc.FlexDiv>
                <sc.FlexDiv>
                    <sc.FlexDivLabel>Office Tools</sc.FlexDivLabel>
                    <IndFilterBox>
                        {selectO365Tools.map((value) =>
                            <sc.ListFilterElement key={value}>
                                { value }:
                                <sc.FilterInput
                                    name={value}
                                    type="checkbox"
                                    checked={formData.O365Tools.includes(value)}
                                    onChange={handle0365ToolsCheckbox}
                                />
                            </sc.ListFilterElement>
                        )}
                    </IndFilterBox>
                </sc.FlexDiv>
                <sc.FlexDiv>
                    <sc.FlexDivLabel>Distribution Lists</sc.FlexDivLabel>
                    <IndFilterBox>
                        {selectDistributionList.map((value) =>
                            <sc.ListFilterElement key={value}>
                                { value }:
                                <sc.FilterInput
                                    name={value}
                                    type="checkbox"
                                    checked={formData.DistributionLists.includes(value)}
                                    onChange={handleDistributionCheckbox}
                                />
                            </sc.ListFilterElement>
                        )}
                    </IndFilterBox>
                </sc.FlexDiv>
                <hr />
                <h5>Interviews</h5>
                <sc.FlexDiv>
                    <sc.FlexDivLabel>Interviewers</sc.FlexDivLabel>
                    <div style={{ flexGrow: "1" }}>
                        <sc.FlexDiv>
                            <Auto add={handleAddRoleInterviewer} />
                        </sc.FlexDiv>
                        <hr />
                        { formData.RoleInterviewers.length > 0 &&
                            <sc.InterviewerList>
                                {formData.RoleInterviewers.map((name, index) =>
                                    <sc.FlexDiv key={index}>
                                        <div style={{ flexGrow: "1" }}>{name}</div>
                                        <sc.Button
                                            name={name}
                                            key={`remove-${name}`}
                                            style={{ flexGrow: "0", margin: "0", fontSize: "0.8em" }}
                                            onClick={handleRemoveRoleInterviewer}
                                        >
                                            Remove
                                        </sc.Button>
                                    </sc.FlexDiv>
                                )}
                            </sc.InterviewerList>
                        }
                    </div>
                </sc.FlexDiv>
                <sc.FlexDiv>
                    <sc.FlexDivLabel>Process Documentation</sc.FlexDivLabel>
                    <textarea
                        name="InterviewProcessDocumentation"
                        type="textarea"
                        value={formData.InterviewProcessDocumentation}
                        placeholder="Please provide a brief interview process description for this role."
                        onChange={handleChange} />
                    </sc.FlexDiv>
                <hr />
                <sc.JobDescPage>
                    <sc.JobDescTitle>{ formData.RoleTitle }</sc.JobDescTitle>
                    <sc.JobDescContent>{ contentJDIntro }</sc.JobDescContent>
                    <sc.JobDescText
                        name="Summary"
                        type="textarea"
                        onChange={event => childChangeHandler("JobDescription", event.target.name, event.target.value)}
                        value={formData.JobDescription?.Summary}
                    />
                    <sc.JobDescHeader>Responsibilities</sc.JobDescHeader>
                    <sc.JobDescTextSmall style={{ marginBottom: "0" }}
                        name="ResponsibilitiesRequired"
                        type="textarea"
                        onChange={event => childChangeHandler("JobDescription", event.target.name, event.target.value)}
                        value={formData.JobDescription?.ResponsibilitiesRequired}
                    />
                    <sc.JobDescTextSmall style={{ marginTop: "0" }}
                        name="ResponsibilitiesOptional"
                        type="textarea"
                        onChange={event => childChangeHandler("JobDescription", event.target.name, event.target.value)}
                        value={formData.JobDescription?.ResponsibilitiesOptional}
                    />
                    <sc.JobDescPageBreak>Page Break</sc.JobDescPageBreak>
                    <sc.JobDescHeader>Required Experience</sc.JobDescHeader>
                    <sc.JobDescTextSmall style={{ marginBottom: "0" }}
                        name="RequiredExperienceRequired"
                        type="textarea"
                        onChange={event => childChangeHandler("JobDescription", event.target.name, event.target.value)}
                        value={formData.JobDescription?.RequiredExperienceRequired}
                    />
                    <sc.JobDescTextSmall style={{ marginTop: "0" }}
                        name="RequiredExperienceOptional"
                        type="textarea"
                        onChange={event => childChangeHandler("JobDescription", event.target.name, event.target.value)}
                        value={formData.JobDescription?.RequiredExperienceOptional}
                    />
                    <sc.JobDescHeader>Preferred Experience</sc.JobDescHeader>
                    <sc.JobDescTextSmall style={{ marginBottom: "0" }}
                        name="PreferredExperienceRequired"
                        type="textarea"
                        onChange={event => childChangeHandler("JobDescription", event.target.name, event.target.value)}
                        value={formData.JobDescription?.PreferredExperienceRequired}
                    />
                    <sc.JobDescTextSmall style={{ marginTop: "0" }}
                        name="PreferredExperienceOptional"
                        type="textarea"
                        onChange={event => childChangeHandler("JobDescription", event.target.name, event.target.value)}
                        value={formData.JobDescription?.PreferredExperienceOptional}
                    />
                    <sc.JobDescPageBreak>Page Break</sc.JobDescPageBreak>
                    <sc.JobDescContentExit>{ contentJDContact }</sc.JobDescContentExit>
                </sc.JobDescPage>
                <div style={{ height: "60px" }} />
                <sc.ButtonsListFloat>
                    <sc.Button style={{ display: "block", margin: "10px auto" }} type="submit" disabled={submitting} onClick={handleSubmit}>
                        { role_id ? 'Save' : 'Submit' }
                    </sc.Button>
                </sc.ButtonsListFloat>
            </form>
            </div> }
        </sc.Section>
    </>
}

const IndFilterBox = styled.div`
    display: inline-block;
    color: #555555;
    font-size: 1em;
    margin: 1em;
    padding: 0.25em 0.25em;
    border-radius: 3px;
    display: #555555;
    background: white;
    flex-direction: column;
    vertical-align: top;
    width: 59.5%;
    border: 1px solid #555555;
`;
