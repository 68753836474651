import React, { Component } from 'react'
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material'

export default class SettingsNav extends Component {
    render() {
        return <List dense>
            <ListItem>
                <ListItemText primary="Settings" />
            </ListItem>
            <ListItem>
                <ListItemButton href='/settings/job-descriptions'>
                    <ListItemText
                        primary="Job Description"
                        secondary="Update Job Description text content"
                    />
                </ListItemButton>
            </ListItem>
            <ListItem>
                <ListItemButton href='/settings/access'>
                    <ListItemText
                        primary="Access"
                        secondary="Update user priviledges"
                    />
                </ListItemButton>
            </ListItem>
        </List>
    }
}
