import React, { useCallback, useEffect, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import styled from 'styled-components'
import * as sc from '../StyledComponents'
import SettingsNav from './SettingsNav'
import { fetchWithToken } from '../../utils/fetchWithToken'

export default function SettingsAccessGroup(props) {
    const email = props.match.params.email

    const [loading, setLoading] = useState(true)
    const [updating, setUpdating] = useState(false)
    const [error, setError] = useState(false)
    const [error_message, setErrorMessage] = useState('')

    const [groups, setGroups] = useState([])
    const [roles, setRoles] = useState([])
    const [available_groups, setAvailableGroups] = useState([])

    useEffect(() => {
        Promise.all([
            fetchWithToken(`${process.env.REACT_APP_ENDPOINT}/api/AppRole/by-email/${email}`),
            fetchWithToken(`${process.env.REACT_APP_ENDPOINT}/api/AppUserGroup/by-email/${email}`),
            fetchWithToken(`${process.env.REACT_APP_ENDPOINT}/api/AppUserGroup`)
        ]).then(([AppRole, AppGroup, AvailableAppGroup]) => {
            console.log('AppRole', AppRole)
            console.log('AppGroup', AppGroup)
            console.log('AvailableAppGroup', AvailableAppGroup)
            setRoles(AppRole.data)
            setGroups(AppGroup.data)
            setAvailableGroups(AvailableAppGroup.data
                .filter(group => AppGroup.data.findIndex(ugroup => ugroup.id === group.id) === -1)
                .map(group => ({ id: group.id, label: group.name }))
            )
            setLoading(false)
        })

    // We need this to run once and just once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleAddGroup = useCallback((event, value) => {
        setUpdating(true)

        let options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: { AppUserEmail: email, AppUserGroupId: value.id }
        }

        fetchWithToken(`${process.env.REACT_APP_ENDPOINT}/api/AppUserGroupMember`, options)
            .then(_ => { window.location.reload(true) })
            .catch(e => {
                setUpdating(false)
                setError(true)
                setErrorMessage(String(e))
            })
    }, [email])

    const handleRemoveGroup = useCallback((id) => {
        setUpdating(true)

        let options = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            data: { AppUserEmail: email, AppUserGroupId: id }
        }

        fetchWithToken(`${process.env.REACT_APP_ENDPOINT}/api/AppUserGroupMember`, options)
            .then(_ => { window.location.reload(true) })
            .catch(e => {
                setUpdating(false)
                setError(true)
                setErrorMessage(String(e))
            })
    }, [email])

    return <sc.Section>
        <sc.ReturnButton href="/">&lt; Return to Dashboard</sc.ReturnButton>
        <PageItems>
            <SettingsNav />
            <Page>
                { loading ? <BannerMessage>Loading User { email }</BannerMessage> : undefined }
                { updating ? <BannerMessage>Updating User { email }</BannerMessage> : undefined }
                { error ? <ErrorMessage>{ error_message }</ErrorMessage> : undefined }

                { loading || updating || error || <>
                    <h1>{ email }</h1>
                    <hr style={{ margin: "24px 0" }} />
                    <h2>Roles</h2>
                    <GroupBox>
                        { roles && roles.length > 0 ? roles.map((role, index) =>
                            <GroupLine style={{ background: 'none', color: 'darkgrey' }} key={index}>{ role.value }</GroupLine>
                        ) : <NoneLine>No Roles assigned to User</NoneLine> }
                    </GroupBox>
                    <hr style={{ margin: "24px 0" }} />
                    <h2>Groups</h2>
                    <GroupBox>
                        { groups && groups.length > 0 ? groups.map((group, index) =>
                            <GroupLine key={index}>
                                { group.name }
                                <GroupLineRemove onClick={() => handleRemoveGroup(group.id)}>Remove</GroupLineRemove>
                            </GroupLine>
                        ) : <NoneLine>User has no Group membership</NoneLine> }
                    </GroupBox>
                    <SmallMessage>Find and Select Group to Add User</SmallMessage>
                    <Autocomplete
                        disablePortal
                        disableClearable
                        onChange={handleAddGroup}
                        options={available_groups}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        style={{ flexGrow: "1" }}
                        renderInput={(params) => <TextField {...params} label="Select Group" InputProps={{ ...params.InputProps, type: 'search', }} />}
                    />
                </> }
            </Page>
        </PageItems>
    </sc.Section>
}

const PageItems = styled.div`
    display: flex;
    aligh-items: center;
    margin-bottom: 5%;
    margin-right: 5%;
`

const Page = styled.div`
    flex-grow: 1;
`

const BannerMessage = styled.div`
    color: lightgrey;
    font-color: grey;
    font-decoration: italic;
    font-weight: bold;
    text-align: center;
`

const ErrorMessage = styled.div`
    color: red;
    font-decoration: italic;
    font-weight: bold;
    text-align: center;
`

const GroupBox = styled.div`
    border: 1px solid lightgrey;
    margin: 12px;
    padding: 0px;
    padding: 0 6px;
`

const GroupLine = styled.div`
    background: darkgrey;
    color: white;
    font-size: 1.2em;
    font-weight: bold;
    margin: 6px 0;
    padding: 6px;
    text-align: center;
    width: 100%;
    position: relative;
`

const GroupLineRemove = styled.button`
    font-size: 0.5em;
    font-weight: normal;
    padding: 2px 4px;
    position: absolute;
    right: 3px;
    text-align: right;
    top: 3px;
`

const NoneLine = styled.div`
    border: 1px solid lightgrey;
    color: lightgrey;
    font-size: 1.2em;
    font-weight: bold;
    margin: 6px 0;
    padding: 6px;
    text-align: center;
    width: 100%;
`

const SmallMessage = styled.div`
    font-size: 0.65em;
    font-variant: small-caps;
    color: darkgrey;
    margin-bottom: 8px;
`
