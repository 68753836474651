import React, { Component } from 'react'
import { RolesList } from './RolesList'
import { fetchWithToken } from '../../utils/fetchWithToken'
import * as sc from '../StyledComponents'

export default class Roles extends Component {
    constructor(props) {
        super(props)

        this.page_number = Number.parseInt(props.match.params.page || 1, 10)
        this.page_results = Number.parseInt(props.match.params.results || 10, 10)

        this.command = props.match.params.command

        this.state = { roles: [] }
    }

    componentDidMount() {
        const pathname = String(this.props.location.search)
        const pathArray = pathname.split("=");
        const input = String(pathArray[pathArray.length - 1])

        fetchWithToken(process.env.REACT_APP_ENDPOINT + `/api/roles${this.command ? `/${this.command}/` : ''}?page=${this.page_number - 1}&results=${this.page_results}`)
            .then((res1) => {
                const data = res1.data
                this.setState({
                    roles: (input === "roles") ? data : data.filter(x => { return Object.keys(x).some(y => String(x[y]).toLowerCase().includes(input.toLowerCase())) })
                })
            })
    }

    render() {
        return <sc.Section>
            <sc.ReturnButton href="/">&lt; Return to Dashboard</sc.ReturnButton>
                <RolesList roles={this.state.roles}></RolesList>
                <sc.FlexDiv>
                    <sc.Button
                        as="a"
                        href={`/roles${this.command ? `/!/${this.command}` : ''}/page/${this.page_number - 1}/${this.page_results}`}
                        style={this.page_number <= 1 ? { visibility: 'hidden' } : {}}
                    >
                        Previous Page
                    </sc.Button>
                    <div style={{ marginTop: "10px", flexGrow: "1", textAlign: "center" }}>Page {this.page_number}</div>
                    <sc.Button
                        as="a"
                        href={`/roles${this.command ? `/!/${this.command}` : ''}/page/${this.page_number + 1}/${this.page_results}`}
                        style={this.state.roles.length < this.page_results ? { visibility: 'hidden' } : {}}
                    >
                        Next Page
                    </sc.Button>
                </sc.FlexDiv>
        </sc.Section>
    }
}
