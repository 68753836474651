import React, { Component } from 'react'
import { Divider, List, ListItem, ListItemButton, ListItemText } from '@mui/material'

export default class Home extends Component {
    static displayName = Home.name;
    constructor(props) {
        super(props)

        this.state = {
            userInput: ''
        }

        this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    };


    handleSearchInputChange(event) {
        this.setState({
            userInput: event.target.value,
        });
    }

    render() {
        return <>
            <List >
                <ListItem>
                    <ListItemButton href='/requests'>
                        <ListItemText
                            primary="Requests"
                            secondary="Create or Update Resourse Requests"
                        />
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton href='/bullhorn'>
                        <ListItemText
                            primary="Bullhorn Search"
                            secondary="Search for Requests in Bullhorn"
                        />
                    </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemButton href='/roles'>
                        <ListItemText
                            primary="Roles"
                            secondary="Configure Roles for future Resource Requests"
                        />
                    </ListItemButton>
                    <ListItemButton href='/settings'>
                        <ListItemText
                            primary="Settings"
                            secondary="Configure the Resource Request application"
                        />
                    </ListItemButton>
                </ListItem>
            </List>
        </>
    }
}
