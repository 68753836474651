import React, { Component } from 'react'
import { Link, AppBar, Avatar, Button, Toolbar, Typography } from '@mui/material'
import { authentication } from '../auth'

export default class NavMenu extends Component {
    constructor(props) {
        super(props)

        this.toggleNavbar = this.toggleNavbar.bind(this)
        this.state = {
            collapsed: true,
            account: authentication.getActiveAccount()
        }
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return <AppBar position="static">
            <Toolbar>
                <Link sx={{ flexGrow: 1 }} color="inherit" href="/">
                    <Typography>Resource Requests</Typography>
                </Link>
                <Button color="inherit" href="/requests">Requests</Button>
                <Button color="inherit" href="/roles">Roles</Button>
                <Button color="inherit" href="/bullhorn">Bullhorn</Button>
                <Button color="inherit" href="/settings">Settings</Button>
                <Avatar style={{ marginLeft: "20px"}} alt={ this.state.account?.name } />
            </Toolbar>
        </AppBar>
    }
}