import { PublicClientApplication, LogLevel } from '@azure/msal-browser'

const clientId = process.env.REACT_APP_MSAL_CLIENTID
const tenantId = process.env.REACT_APP_MSAL_TENANTID
const redirectUri = '/'

const configuration = {
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  auth: {
    clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri,
    postLogoutRedirectUri: redirectUri,
  },
  system: {
    loggerOptions: {
        loggerCallback: (level, message, contains_pii) => {
            if (contains_pii) {
                console.info('msal-browser', '*'.repeat(message.length))
                return
            }

            switch (level) {
                case LogLevel.Error:
                    console.error('msal-browser', message)
                    return

                case LogLevel.Verbose:
                    console.debug('msal-browser', message)
                    return

                case LogLevel.Warning:
                    console.warn('msal-browser', message)
                    return

                case LogLevel.Info:
                default:
                    console.info('msal-browser', message)
                    return
            }
        }
    }
  }
}

export const authentication = new PublicClientApplication(configuration)
