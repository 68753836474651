import React, { useState, useEffect } from 'react'
import { Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer'
import logo from '../images/launchlogo.jpg'

export default function JobDescriptionDocument (props) {
    const [request, setRequest] = useState(props.request)
    useEffect(() => { setRequest(props.request) }, [props.request])

    // const [project, setProject] = useState(props.project)
    // useEffect(() => { setProject(props.project) }, [props.project])

    const [introduction, setIntroduction] = useState(props.introduction)
    useEffect(() => { setIntroduction(props.introduction) }, [props.introduction])

    const [contact_statement, setContactStatement] = useState(props.contact_statement)
    useEffect(() => { setContactStatement(props.contact_statement) }, [props.contact_statement])

    const styles = StyleSheet.create({
        page: {
            fontFamily: 'Helvetica',
            margin: '0.5in',
            lineHeight: 1.2,
            letterSpacing: '0.2px'
        },
        section: {
            fontSize: '14px',
            marginTop: '0.25in',
            textAlign: 'left',
            width: '7.0in'
        },
        image: {
            height: '75px',
            width: '75px'
        },
        imagesection: {
            marginTop: 10,
            textAlign: 'right',
            marginBottom: 0,
            width: '7.0in',
            height: '1.0in'
        },
        title: {
            fontSize: '24px',
            fontWeight: 'bold'
        },
        header: {
            fontSize: '18px',
            fontWeight: 'bold'
        }
    })

    return <Document>
        <Page font='Helvetica' size="A4" style={styles.page} wrap>
            <View style={styles.imagesection} fixed>
                <Text>
                    <Image style={styles.image} src={logo} alt="images" />
                </Text>
            </View>

            <View style={{...styles.section, ...styles.title}} >
                <Text>{request.ProjectTitle || request.RoleTitle || 'Launch Role'}</Text>
            </View>

            <View style={styles.section}>
                <Text>{introduction ?? ''}</Text>
                <Text>{request.JobDescription?.Summary ?? ''}</Text>
                <Text>{request.JobDescription?.ProjectSummary ?? ''}</Text>
            </View>

            <View style={[styles.section,  styles.header]}>
                <Text>Responsibilities</Text>
            </View>
            <View style={styles.section}>
                <Text>{request.JobDescription?.ResponsibilitiesRequired ?? ''}</Text>
                <Text>{request.JobDescription?.ResponsibilitiesOptional ?? ''}</Text>
            </View>
        </Page>
        <Page font='Helvetica' size="A4" style={styles.page} wrap>
            <View style={[styles.section,  styles.header]}>
                <Text>Required Experience</Text>
            </View>
            <View style={styles.section}>
                <Text>{request.JobDescription?.RequiredExperienceOptional ?? ''}</Text>
                <Text>{request.JobDescription?.RequiredExperienceRequired ?? ''}</Text>
            </View>

            <View style={[styles.section,  styles.header]}>
                <Text>Preferred Experience</Text>
            </View>
            <View style={styles.section}>
                <Text>{request.JobDescription?.PreferredExperienceOptional ?? ''}</Text>
                <Text>{request.JobDescription?.PreferredExperienceRequired ?? ''}</Text>
            </View>
        </Page>
        <Page font='Helvetica' size="A4" style={styles.page} wrap>
            <View style={styles.section}>
                <Text>{contact_statement ?? ''}</Text>
            </View>
        </Page>
    </Document>
}
