import React, { Component } from 'react';
import { RolesRow } from './RolesRow';

export class RolesList extends Component {
    render() {
        return <table className="RoleList">
            <tbody>
                {this.props.roles.map((roles) =>
                    (roles.id !== 0) && (roles.RoleTitle !== "Select Role") && (
                        <RolesRow key={roles.id} roles={roles}>{roles.id}</RolesRow>
                    )
                )}
            </tbody>
        </table>
    }
}