import React, { useCallback, useEffect, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import styled from 'styled-components'
import * as sc from '../StyledComponents'
import Auto from '../Auto'
import SettingsNav from './SettingsNav'
import { fetchWithToken } from '../../utils/fetchWithToken'

export default function SettingsAccessGroup(props) {
    const group_id = props.match.params.id

    const [loading, setLoading] = useState(true)
    const [updating, setUpdating] = useState(false)
    const [error, setError] = useState(false)
    const [error_message, setErrorMessage] = useState('')

    const [group, setGroup] = useState('')
    const [users, setUsers] = useState([])
    const [roles, setRoles] = useState([])
    const [available_roles, setAvailableRoles] = useState([])

    useEffect(() => {
        const effect = async () => {
            Promise.all([
                fetchWithToken(`${process.env.REACT_APP_ENDPOINT}/api/AppUserGroup/${group_id}`),
                fetchWithToken(`${process.env.REACT_APP_ENDPOINT}/api/AppRole`),
                fetchWithToken(`${process.env.REACT_APP_ENDPOINT}/api/AppRole/by-group/${group_id}`),
                fetchWithToken(`${process.env.REACT_APP_ENDPOINT}/api/AppUserGroupMember/by-group/${group_id}`)
            ]).then(([AppUserGroup, AppRole, GroupAppRole, GroupAppUser]) => {
                console.log('AppUserGroup', AppUserGroup)
                console.log('AppRole', AppRole)
                console.log('GroupAppRole', GroupAppRole)
                console.log('GroupAppUser', GroupAppUser)
                setGroup(AppUserGroup.data.result)
                setRoles(GroupAppRole.data)
                setUsers(GroupAppUser.data)
                setAvailableRoles(AppRole.data
                    .filter(role => GroupAppRole.data.findIndex(grole => grole.id === role.id) === -1)
                    .map(role => ({ id: role.id, label: role.value }))
                )
                setLoading(false)
            })
        }

        effect()

    // We need this to run once and just once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleAddRole = useCallback((id) => {
        setUpdating(true)

        let options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: { AppUserGroupId: group.id, AppRoleId: id }
        }

        fetchWithToken(`${process.env.REACT_APP_ENDPOINT}/api/AppUserGroupRole`, options)
            .then(_ => { window.location.reload(true) })
            .catch(e => {
                setUpdating(false)
                setError(true)
                setErrorMessage(String(e))
            })
    }, [group])


    const handleRemoveRole = useCallback((id) => {
        setUpdating(true)

        let options = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            data: { AppUserGroupId: group.id, AppRoleId: id }
        }

        fetchWithToken(`${process.env.REACT_APP_ENDPOINT}/api/AppUserGroupRole`, options)
            .then(_ => { window.location.reload(true) })
            .catch(e => {
                setUpdating(false)
                setError(true)
                setErrorMessage(String(e))
            })
    }, [group])

    const handleRemoveUser = useCallback((email) => {
        setUpdating(true)

        let options = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            data: { AppUserGroupId: group.id, AppUserEmail: email }
        }

        fetchWithToken(`${process.env.REACT_APP_ENDPOINT}/api/AppUserGroupMember`, options)
            .then(_ => { window.location.reload(true) })
            .catch(e => {
                setUpdating(false)
                setError(true)
                setErrorMessage(String(e))
            })
    }, [group])

    const handleAddUser = useCallback(user => {
        setUpdating(true)

        let options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: { AppUserEmail: user.id, AppUserGroupId: group.id }
        }

        fetchWithToken(`${process.env.REACT_APP_ENDPOINT}/api/AppUserGroupMember`, options)
            .then(_ => { window.location.reload(true) })
            .catch(e => {
                setUpdating(false)
                setError(true)
                setErrorMessage(String(e))
            })
    }, [group])

    return <sc.Section>
        <sc.ReturnButton href="/">&lt; Return to Dashboard</sc.ReturnButton>
        <PageItems>
            <SettingsNav />
            <Page>
                { loading ? <BannerMessage>Loading Access Group { group_id }</BannerMessage> : undefined }
                { updating ? <BannerMessage>Updating Access Group { group_id }</BannerMessage> : undefined }
                { error ? <ErrorMessage>{ error_message }</ErrorMessage> : undefined }

                { loading || updating || error || <>
                    <h1>{ group.name }</h1>
                    <h3>{ group.id }</h3>
                    <hr style={{ margin: "24px 0" }} />
                    <h2>Roles</h2>
                    <GroupBox>
                        { roles && roles.length > 0 ? roles.map((role, index) =>
                            <GroupLine key={index}>
                                { role.value }
                                <GroupLineRemove onClick={() => handleRemoveRole(role.id)}>Remove</GroupLineRemove>
                            </GroupLine>
                        ) : <NoneLine>No Roles in Group</NoneLine> }
                    </GroupBox>
                    <div>
                        <SmallMessage>Select Role to Add to Group</SmallMessage>
                        <Autocomplete
                            disablePortal
                            disableClearable
                            onChange={(_, value) => handleAddRole(value.id)}
                            options={available_roles}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            style={{ flexGrow: "1" }}
                            renderInput={(params) => <TextField {...params} label="Select Role" InputProps={{ ...params.InputProps, type: 'search', }} />}
                        />
                    </div>
                    <hr style={{ margin: "24px 0" }} />
                    <h2>Users</h2>
                    <UserBox>
                        { users && users.length > 0 ? users.map((user, index) =>
                            <UserLine key={index}>
                                { user.appUserEmail }
                                <UserLineRemove onClick={() => handleRemoveUser(user.appUserEmail)}>Remove</UserLineRemove>
                            </UserLine>
                        ) : <NoneLine>No Users in Group</NoneLine> }
                    </UserBox>
                    <SmallMessage>Find and Select Employee to Add to Group</SmallMessage>
                    <sc.FlexDiv>
                        <Auto add={handleAddUser} />
                    </sc.FlexDiv>
                    <div style={{ height: "64px" }} />
                </> }
            </Page>
        </PageItems>
    </sc.Section>
}

const PageItems = styled.div`
    display: flex;
    aligh-items: center;
    margin-bottom: 5%;
    margin-right: 5%;
`

const Page = styled.div`
    flex-grow: 1;
`

const BannerMessage = styled.div`
    color: lightgrey;
    font-color: grey;
    font-decoration: italic;
    font-weight: bold;
    text-align: center;
`

const ErrorMessage = styled.div`
    color: red;
    font-decoration: italic;
    font-weight: bold;
    text-align: center;
`

const GroupBox = styled.div`
    border: 1px solid lightgrey;
    margin: 12px;
    padding: 0 6px;
`

const GroupLine = styled.div`
    background: darkgrey;
    color: white;
    font-size: 1.2em;
    font-weight: bold;
    margin: 6px 0;
    padding: 6px;
    text-align: center;
    width: 100%;
    position: relative;
`

const GroupLineRemove = styled.button`
    font-size: 0.5em;
    font-weight: normal;
    padding: 2px 4px;
    position: absolute;
    right: 3px;
    text-align: right;
    top: 3px;
`

const UserBox = styled.div`
    border: 1px solid lightgrey;
    margin: 12px;
    padding: 0 6px;
`

const UserLine = styled.div`
    background: darkgrey;
    color: white;
    font-size: 1.2em;
    font-weight: bold;
    margin: 6px 0;
    padding: 6px;
    width: 100%;
    position: relative;
`

const UserLineRemove = styled.button`
    font-size: 0.5em;
    font-weight: normal;
    padding: 2px 4px;
    position: absolute;
    right: 3px;
    text-align: right;
    top: 3px;
`

const NoneLine = styled.div`
    border: 1px solid lightgrey;
    color: lightgrey;
    font-size: 1.2em;
    font-weight: bold;
    margin: 6px 0;
    padding: 6px;
    text-align: center;
    width: 100%;
`

const SmallMessage = styled.div`
    font-size: 0.65em;
    font-variant: small-caps;
    color: darkgrey;
    margin-bottom: 8px;
`
