import React, { Component } from 'react'
import { RequestList } from './RequestList'
import { fetchWithToken } from '../../utils/fetchWithToken'
import * as sc from '../StyledComponents'

export default class Requests extends Component {
    static displayName = Requests.name;
    constructor(props) {
        super(props)

        this.page_number = Number.parseInt(props.match.params.page || 1, 10)
        this.page_results = Number.parseInt(props.match.params.results || 10, 10)

        this.command = props.match.params.command

        this.state = { requests: [] }
    }

    componentDidMount() {
        const pathname = String(this.props.location.search)
        const pathArray = pathname.split("=");
        const input = String(pathArray[pathArray.length - 1])
        const headers = { 'Content-Type': 'application/json' }

        fetchWithToken(`${process.env.REACT_APP_ENDPOINT}/api/requests${this.command ? `/${this.command}/` : ''}?page=${this.page_number - 1}&results=${this.page_results}`, { headers })
            .then(res1 => {
                const data = res1.data;
                this.setState({
                    requests: (input === "requests") ? data : data.filter(x => {
                        return Object.keys(x).some(y => String(x[y]).toLowerCase().includes(input.toLowerCase()))
                    })
                })
            })
    }

    render() {
        return <sc.Section>
            <sc.ReturnButton href="/">&lt; Return to Dashboard</sc.ReturnButton>
            <RequestList requests={this.state.requests}></RequestList>
            <sc.FlexDiv>
                <sc.Button
                    as="a"
                    href={`/requests${this.command ? `/!/${this.command}` : ''}/page/${this.page_number - 1}/${this.page_results}`}
                    style={this.page_number <= 1 ? { visibility: 'hidden' } : {}}
                >
                    Previous Page
                </sc.Button>
                <div style={{ marginTop: "10px", flexGrow: "1", textAlign: "center" }}>Page {this.page_number}</div>
                <sc.Button
                    as="a"
                    href={`/requests${this.command ? `/!/${this.command}` : ''}/page/${this.page_number + 1}/${this.page_results}`}
                    style={this.state.requests.length < this.page_results ? { visibility: 'hidden' } : {}}
                >
                    Next Page
                </sc.Button>
            </sc.FlexDiv>
        </sc.Section>
    }
}
