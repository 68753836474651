import React, { Component } from 'react'
import { fetchWithToken } from '../../utils/fetchWithToken'
import { Link } from 'react-router-dom'
import { saveAs as download_file } from 'file-saver'
import { pdf as generate_pdf } from '@react-pdf/renderer'
import JobDescriptionDocument from '../JobDescriptionDocument'

export class RequestRow extends Component {
    static displayName = RequestRow.name;
    constructor(props) {
        super(props)

        this.request = props.request
        this.state = {
            hide: false,
        }
    }

    state_color (state) {
        switch (String(state)) {
            case 'Notify Recruiting':
                return 'orange'

            case 'Recruiting Notified':
                return 'green'

            case 'Complete':
                return 'grey'

            case 'Submitted':
            default:
                return 'red'
        }
    }

    render() {
        const download = async (request_id) => {
            let jd_introduction
            let jd_contact_statement
            let request
            let project

            await Promise.all([
                fetchWithToken(process.env.REACT_APP_ENDPOINT + '/api/form-setting/content/job-description-introduction'),
                fetchWithToken(process.env.REACT_APP_ENDPOINT + '/api/form-setting/content/job-description-contact-statement')
            ])
                .then(([result_jd_introduction, result_jd_contact_statement]) => {
                    jd_introduction = result_jd_introduction.data.result.content
                    jd_contact_statement = result_jd_contact_statement.data.result.content
                })
                .then(_ => fetchWithToken(`${process.env.REACT_APP_ENDPOINT}/api/requests/${request_id}`))
                .then(result => request = result.data.result)
                .then(_ => fetchWithToken(`${process.env.REACT_APP_ENDPOINT}/api/projector-projects/${request.ProjectCode}`))
                .then(result => project = result.data.result)
                .catch(error => console.error(error))

            const blob = await generate_pdf((
                <JobDescriptionDocument
                    introduction={jd_introduction}
                    contact_statement={jd_contact_statement}
                    request={request}
                    project={project}
                />
            )).toBlob()

            const name_title = (request.ProjectTitle || request.RoleTitle || 'Launch Role')
                .replace(/\s+/g, '.')
                .replace(/\.+/g, '.')

            const name = `${name_title}.${request.id}.pdf`

            download_file(blob, name)
        }

        const state_style = {
            fontSize: '0.9em',
            fontWeight: 'bold',
            textAlign: 'center',
            color: 'white',
            background: this.state_color(this.request.State)
        }

        return (
            // This will allow the row to hide itself if hide is true.
            !this.state.hide &&
            <tr className='RequestListItem'>
                <td><div style={state_style}>{this.request.State || 'Submitted'}</div></td>
                <td><b>{this.request.ProjectTitle}</b></td>
                <td>{this.request.RoleTitle}</td>
                <td style={{ fontSize: "0.6em"}} colSpan={2}>{this.request.id}</td>
                <td>{this.request.ProjectCode}</td>
                <td>{this.request.StartDateFE}</td>
                <td>
                    <button className='actionButton' onClick={() => download(this.request.id)} >Download<br /><span style={{ fontSize: '0.8em' }}>Job Description</span></button>
                </td>
                <td>
                    <Link to={`/requests/edit/${this.request.id}`}>
                        <button className='actionButton' id='settingsButton' type="button">Edit</button>
                    </Link>
                </td>
            </tr>
        )
    }
}


