import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { debounce } from 'lodash'
import { Autocomplete, TextField } from '@mui/material'
import { fetchWithToken } from '../utils/fetchWithToken'

export default function Auto(props) {
    const [options, setOptions] = useState([])

    const [target, setTarget] = useState(props.target)
    useEffect(() => { setTarget(props.target) }, [props.target])

    const [componentValue, setComponentValue] = useState({ id: '', label: '' })
    const [inputValue, setInputValue] = useState(props.value || '')

    const [label, setLabel] = useState(props.label || '')
    useEffect(() => { setLabel(props.label) }, [props.label])

    const load = useCallback((value) => {
        if ((value || '').length < 1) {
            setOptions([])
            return
        }

        return fetchWithToken(`${process.env.REACT_APP_ENDPOINT}/api/${target}/by-query/${value}`)
            .then((response) => {
                const options = (response.data || []).map(result => {
                    return { label: result.label || '', id: result.id || '' }
                })

                if ((options || null) !== null) {
                    setOptions(options)
                    const option = options.find(option => option.id === value)
                    if (option) {
                        setComponentValue(option)
                    }
                }
            }).catch(err => {
                console.error(err)
            })
    }, [target])

    const debounced_load = useMemo(() => debounce(load, 200), [load])

    useEffect(() => {
        load(props.value)
    }, [load, props.value])

    const { set: setValue } = props
    useEffect(() => {
        debounced_load.cancel()

        if (!componentValue?.id) {
            return
        }

        setValue(componentValue.id)
    }, [setValue, debounced_load, componentValue])

    return (
        <Autocomplete
            disablePortal
            disableClearable
            blurOnSelect
            style={{ flexGrow: "1" }}
            isOptionEqualToValue={(option, value) => option ? option.id === value.id : false}
            value={componentValue}
            inputValue={inputValue}
            onInputChange={(_, value) => {
                setInputValue(value)

                if (String(value).length >= 2) {
                    debounced_load(value)
                }
            }}
            onChange={(_, value) => { setComponentValue(value) }}
            options={options}
            renderInput={(params) =>
                <TextField {...params} label={label} InputProps={{ ...params.InputProps, type: 'search', }} />
            }
            renderOption={(params, option) =>
                <li {...params}><span style={{ fontSize: '0.7em', whiteSpace: 'nowrap', color: 'white', background: 'lightgrey', padding: '6px', marginRight: '10px' }}>{option.id}</span> {option.label}</li>
            }
        />
    )
}
