import React, { Component } from 'react'
import styled from 'styled-components'
import * as sc from '../StyledComponents'
import SettingsNav from './SettingsNav'

export default class Settings extends Component {
    static displayName = Settings.name;
    constructor(props) {
        super(props);
        this.state = {
            jobDescriptionClicked: false,
        };

        this.handleJobDescription = this.handleJobDescription.bind(this);
    }

    handleJobDescription() {
        this.setState({
            jobDescriptionClicked: true,
        });
    }
    render() {
        return <sc.Section>
            <sc.ReturnButton href="/">&lt; Return to Dashboard</sc.ReturnButton>
            <PageItems>
                <SettingsNav />
                <div style={{ margin: '48px 0px', color: 'grey', textAlign: 'center'}}>
                    Select an option from the menu to the left to continue
                </div>
            </PageItems>
        </sc.Section>
    }
}

const PageItems = styled.div`
    display: flex;
    aligh-items: center;
    margin-bottom: 5%;
    margin-right: 5%;
`;
