import React, { useReducer, useState, useEffect, useCallback } from 'react'
import { TextField } from '@mui/material'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { fetchWithToken } from '../../utils/fetchWithToken'
import { authentication } from '../../auth'
import Auto from '../Auto'
import AutoSearch from '../AutoSearch'
import RequestDefaultValues from './RequestDefaultValues.json'
import * as sc from '../StyledComponents'

const FormReducer = (state, event) => ({
    ...state,
    ...event
})

export default function RequestForm(props) {
    const domain = process.env.REACT_APP_ENDPOINT
    const account = authentication.getActiveAccount()
    const request_id = props.match.params.id

    const [submit, setSubmit] = useState(false)

    const [pickerStartDate, setPickerStartDate] = useState(null)
    const [pickerEndDate, setPickerEndDate] = useState(null)

    const [loading, setLoading] = useState(true)

    const [formData, setFormData] = useReducer(FormReducer, RequestDefaultValues)
    const [project_object, setProjectObject] = useState(null)
    const [submitting, setSubmitting] = useState(false)

    const [roles, setRoles] = useState([])
    const [groups, setGroups] = useState([])

    const [contentJDIntro, setContentJDIntro] = useState(true)
    const [contentJDContact, setContentJDContact] = useState(true)

    const [ownerIsLoading, setOwnerIsLoading] = useState(true)

    const [form_work_locations, setWorkLocations] = useState([])
    const [form_distribution_lists, setDistributionLists] = useState([])

    const [form_jd_defaults, setJDDefaults] = useState(RequestDefaultValues.JobDescription)
    const [form_jd_reset, setJDReset] = useState(RequestDefaultValues.JobDescription)
    const [form_jd_reset_role, setJDResetRole] = useState('')

    useEffect(() => {
        const effect = async () => {
            let promises = []

            promises.push(
                fetchWithToken(process.env.REACT_APP_ENDPOINT + '/api/form-setting/request/work-locations')
                .then((response) => {
                    setWorkLocations(response.data.result.options)
                })
                .catch(e => console.error(e))
            )

            promises.push(
                fetchWithToken(process.env.REACT_APP_ENDPOINT + '/api/form-setting/request/distribution-lists')
                .then((response) => {
                    setDistributionLists(response.data.result.options)
                })
                .catch(e => console.error(e))
            )

            promises.push(
                fetchWithToken(process.env.REACT_APP_ENDPOINT + '/api/roles').then((response) => {
                    setRoles(response.data.map(role => {
                        return { id: role.id, title: role.RoleTitle }
                    }))
                }).catch(e => console.error(e))
            )

            await Promise.all(promises)

            await fetchWithToken(process.env.REACT_APP_ENDPOINT + `/api/AppUserGroup`)
                .then((response) => setGroups(response.data))
                .catch(e => console.error(e))

            await Promise.all([
                fetchWithToken(process.env.REACT_APP_ENDPOINT + '/api/form-setting/content/job-description-introduction'),
                fetchWithToken(process.env.REACT_APP_ENDPOINT + '/api/form-setting/content/job-description-contact-statement')
            ])
            .then(([jdIntroResult, jdContactStateResult]) => {
                setContentJDIntro(jdIntroResult.data.result.content)
                setContentJDContact(jdContactStateResult.data.result.content)
            });

            if ((request_id || null) === null) {
                setFormData({ Owner: account?.name })
                if(ownerIsLoading && formData.Owner && formData.Owner.length > 0) {
                    setOwnerIsLoading(false);
                }
                setLoading(false)
                return
            }

            await fetchWithToken(`${domain}/api/requests/${request_id}`).then((result) => {
                console.log('Request Loaded', result.data.result)
                setFormData(result.data.result)
                if (result.data.result.JobDescription === null) {
                    setFormData({ JobDescription: RequestDefaultValues.JobDescription })
                }
                setJDResetRole(result.data.result.RoleId)
                setJDReset(result.data.result.JobDescription)
            }).catch(err => {
                console.error(err)
            })

            setLoading(false)
        }

        effect()

    // We need this to run once and just once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setPickerStartDate(dayjs(formData.StartDateFE))
    }, [formData.StartDateFE])

    useEffect(() => {
        fetchWithToken(`${process.env.REACT_APP_ENDPOINT}/api/projector-projects/${formData.ProjectCode}`)
        .then((response) => {
            setProjectObject(response.data.result)
        }).catch(e => console.error(e))
    }, [formData.ProjectCode])

    useEffect(() => {
        setPickerEndDate(dayjs(formData.EndDateFE))
    }, [formData.EndDateFE])

    const handleSubmit = useCallback(event => {
        if (event) {
            event.preventDefault()
        }

        const url = `${process.env.REACT_APP_ENDPOINT}/api/requests${request_id ? '/' : ''}${request_id || ''}`
        let options = {
            method: request_id ? 'PUT' : 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: formData
        }

        console.log('Request POST', formData, JSON.stringify(formData))

        fetchWithToken(url, options)
            .then(function () {
                setSubmitting(false)
                window.location = "/requests"
            })
    }, [formData, request_id])

    useEffect(() => {
        if (submit) {
            handleSubmit()
        }
    }, [handleSubmit, submit])

    const handleSubmitToRecruit = useCallback(event => {
        setFormData({ State: 'Notify Recruiting' })
        setSubmit(true)
    }, [])

    const handleRoleChange = useCallback(event => {
        const role_id = event.target.value

        setFormData({
            RoleId: role_id
        })

        fetchWithToken(`${process.env.REACT_APP_ENDPOINT}/api/roles/${role_id}`)
            .then((payload) => {
                const JobDescription = request_id && role_id === form_jd_reset_role
                    ? {
                        ResponsibilitiesRequired: form_jd_reset.ResponsibilitiesRequired,
                        RequiredExperienceRequired: form_jd_reset.RequiredExperienceRequired,
                        PreferredExperienceRequired: form_jd_reset.PreferredExperienceOptional,
                    }
                    : {
                        ResponsibilitiesRequired: '',
                        RequiredExperienceRequired: '',
                        PreferredExperienceRequired: ''
                    }

                setFormData({ RoleTitle: payload.data.result.RoleTitle, JobDescription })
                setJDDefaults(payload.data.result.JobDescription || {})
            })
    }, [form_jd_reset_role, form_jd_reset, request_id])

    const handleProjectChange = useCallback(value => {
        const project_code = value
        setFormData({
            ProjectCode: project_code
        })
    }, [])

    const handleUnnamedRoleChange = useCallback(value => {
        setFormData({
            ProjectorRoleID: value
        })
    }, [])

    const handleRedeploymentResourceChange = useCallback(value => {
        setFormData({
            RedeployedResource: value
        })
    }, [])

    const handleBackfillResourceChange = useCallback(value => {
        setFormData({
            BackfilledResource: value
        })
    }, [])

    const handleBoolChange = useCallback(event => {
        setFormData({
            [event.target.name]: event.target.value === "true"
        })
    }, [])

    const handleChange = useCallback(event => {
        setFormData({
            [event.target.name]: event.target.value
        })
    }, [])

    const childChangeHandler = useCallback((parent, name, value) => {
        let obj = formData[parent];
        obj[name] = value;
        setFormData({
            [parent]: obj
        })
    }, [formData])

    const handleDistributionCheckbox = useCallback(event => {
        // Add checkbox value to formData when checked
        let newArray = []
        if (event.target.checked) {
            newArray = [...formData.DistributionGroupsRequest, event.target.name]
        } else {
            // Remove checkbox value from formData when unchecked
            newArray = formData && formData.DistributionGroupsRequest && formData.DistributionGroupsRequest.filter(x => x !== event.target.name);
        }
        setFormData({
            DistributionGroupsRequest: newArray
        })
    }, [formData])

    const handleAddInterviewer = useCallback((value) => {
        if (!value) {
            return
        }

        setFormData({
            requestsInterviewers: [...formData.requestsInterviewers, `${value.id}/${value.label}`]
        })
    }, [formData.requestsInterviewers])

    const handleRemoveInterviewer = useCallback((e) => {
        e.preventDefault()

        const myRequestsInterviewers = [...formData.requestsInterviewers]
        const index = myRequestsInterviewers.indexOf(e.target.name);
        myRequestsInterviewers.splice(index, 1);
        setFormData({
            requestsInterviewers: myRequestsInterviewers
        })
    }, [formData.requestsInterviewers])

    const handleSetOwner = useCallback((value) => {
        setFormData({ ownerUserEmail: value.id })
    }, [])

    return <>
        <sc.Section>
            <sc.ButtonsList>
                <sc.ReturnButton as="a" href="/requests">&lt; Return to Requests</sc.ReturnButton>
            </sc.ButtonsList>
            { loading ? <sc.LoadingDiv>Loading Content</sc.LoadingDiv> : <div>
                <form className="RequestForm" onSubmit={(e) => e.preventDefault()}>
                    <div style={{ textAlign: "center", margin: "0 24px", minHeight: "100px" }}>
                        { formData.BullhornID &&
                        <div style={{ margin: "12px 0 0 0", color: "grey" }}>
                            <a href={`/requests/bullhorn/${request_id}`}>
                                Bullhorn Job #{formData.BullhornID}
                            </a>
                        </div> }
                        <h1 style={formData.ProjectTitle || formData.RoleTitle ? {} : { color: "lightgrey" }}>
                            { formData.ProjectTitle || formData.RoleTitle || 'Resource Request'}
                        </h1>
                        { request_id &&
                        <div style={{ margin: "12px 0 0 0", color: "grey" }}>
                            <div>{ request_id }</div>
                            <hr />
                            { (formData.ProjectTitle || formData.RoleTitle) || <div>Resource Request</div> }
                        </div> }
                        { project_object &&
                        <div style={{ margin: "0 0 24px 0", color: "grey" }}>
                            <div style={{ fontWeight: "bold" }}>
                                { project_object.project }
                            </div>
                            <div>{ project_object.projectStage === "Booked" ? project_object.projectStage : `Stage ${project_object.projectStage}` }</div>
                            <div style={{ fontSize: "0.8em"}}>{ project_object.engagementPrimaryCostCenterName }</div>
                            { project_object.engagementManagerEmailAddress === project_object.projectManagerEmailAddress
                                ? <div style={{ fontSize: "0.8em"}}>{ project_object.engagementManager } (em/pm)</div>
                                : <div style={{ fontSize: "0.8em"}}>{ project_object.engagementManager } (em) &bull; { project_object.projectManager } (pm)</div>
                            }
                        </div> }

                    </div>
                    <sc.FlexDiv>
                        <sc.FlexDivLabel>Owner</sc.FlexDivLabel>
                        <Auto add={handleSetOwner} valued value={formData.ownerUserEmail} />
                    </sc.FlexDiv>
                    <sc.FlexDiv>
                        <sc.FlexDivLabel>Owning Group</sc.FlexDivLabel>
                        <select name="ownerGroupId" value={formData.ownerGroupId} onChange={handleChange}>
                            <option default></option>
                            {groups.map((value) => <option value={value.id} key={value.id}>{value.name}</option>)}
                        </select>
                    </sc.FlexDiv>
                    <hr />
                    <sc.FlexDiv>
                        <sc.FlexDivLabel>Project</sc.FlexDivLabel>
                        <AutoSearch target="projector-projects" set={handleProjectChange} value={formData.ProjectCode} />
                    </sc.FlexDiv>
                    <sc.FlexDiv>
                        <sc.FlexDivLabel>Unnamed Role</sc.FlexDivLabel>
                        <AutoSearch target="projector-unnamed-roles" set={handleUnnamedRoleChange} value={formData.ProjectorRoleID} />
                    </sc.FlexDiv>
                    <sc.FlexDiv>
                        <sc.FlexDivLabel>Start Date</sc.FlexDivLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={pickerStartDate}
                                onChange={(newValue) => { setPickerStartDate(newValue) }}
                                renderInput={(params) => <TextField style={{ flexGrow: "1" }} {...params} />}
                            />
                        </LocalizationProvider>
                    </sc.FlexDiv>
                    <sc.FlexDiv>
                        <sc.FlexDivLabel>End Date</sc.FlexDivLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={pickerEndDate}
                                onChange={(newValue) => { setPickerEndDate(newValue) }}
                                renderInput={(params) => <TextField style={{ flexGrow: "1" }} {...params} />}
                            />
                        </LocalizationProvider>
                    </sc.FlexDiv>
                    <sc.FlexDiv>
                        <sc.FlexDivLabel>Redeployment</sc.FlexDivLabel>
                        <select name="Redeployment" onChange={handleBoolChange} value={formData.Redeployment}>
                            <option value={false} default>No</option>
                            <option value={true}>Yes</option>
                        </select>
                    </sc.FlexDiv>
                    { formData.Redeployment && <sc.FlexDiv>
                        <sc.FlexDivLabel>Redeployed Resource</sc.FlexDivLabel>
                        <AutoSearch target="projector-resources" set={handleRedeploymentResourceChange} value={formData.RedeployedResource} />
                    </sc.FlexDiv> }
                    <sc.FlexDiv>
                        <sc.FlexDivLabel>Backfill</sc.FlexDivLabel>
                        <select name="Backfill" onChange={handleBoolChange} value={formData.Backfill}>
                            <option value={false} default>No</option>
                            <option value={true}>Yes</option>
                        </select>
                    </sc.FlexDiv>
                    { formData.Backfill && <sc.FlexDiv>
                        <sc.FlexDivLabel>Backfilled Resource</sc.FlexDivLabel>
                        <AutoSearch target="projector-resources" set={handleBackfillResourceChange} value={formData.BackfilledResource} />
                    </sc.FlexDiv> }
                    <sc.FlexDiv>
                        <sc.FlexDivLabel>
                            Corp-to-Corp
                            <sc.FlexDivLabelSub>Are Corp-to-Corp Resources Allowed?</sc.FlexDivLabelSub>
                        </sc.FlexDivLabel>
                        <select name="C2CResourcesAllowed" onChange={handleBoolChange} value={formData.C2CResourcesAllowed}>
                            <option value={false} default>No</option>
                            <option value={true}>Yes</option>
                        </select>
                    </sc.FlexDiv>
                    <sc.FlexDiv>
                        <sc.FlexDivLabel>
                            Offshore
                            <sc.FlexDivLabelSub>Is Offshore Allowed?</sc.FlexDivLabelSub>
                        </sc.FlexDivLabel>
                        <select name="OffshoreOkay" onChange={handleBoolChange} value={formData.OffshoreOkay}>
                            <option value={false} default>No</option>
                            <option value={true}>Yes</option>
                        </select>
                    </sc.FlexDiv>
                    <sc.FlexDiv>
                        <sc.FlexDivLabel>Resource Type</sc.FlexDivLabel>
                        <select name="resourceType" onChange={handleChange} value={formData.resourceType || ''}>
                            <option value="internal" default>Internal</option>
                            <option value="external">External</option>
                        </select>
                    </sc.FlexDiv>
                    <sc.FlexDiv>
                        <sc.FlexDivLabel>Work Capacity</sc.FlexDivLabel>
                        <select name="workCapacity" onChange={handleChange} value={formData.workCapacity || ''}>
                            <option value="fullTime" default>Full-Time</option>
                            <option value="partTime">Part-Time</option>
                        </select>
                    </sc.FlexDiv>
                    <sc.FlexDiv>
                        <sc.FlexDivLabel>Work Location</sc.FlexDivLabel>
                        <select name="workLocation" onChange={handleChange} value={formData.workLocation || ''}>
                            { form_work_locations.map((option, index) =>
                                <option key={index} value={option}>{option}</option>
                            ) }
                        </select>
                    </sc.FlexDiv>
                    <hr />
                    <sc.FlexDiv>
                        <sc.FlexDivLabel>Interviewers</sc.FlexDivLabel>
                        <div style={{ flexGrow: "1" }}>
                            <sc.FlexDiv>
                                <Auto add={handleAddInterviewer} />
                            </sc.FlexDiv>
                            <hr />
                            { formData && formData.requestsInterviewers && formData.requestsInterviewers.length > 0 &&
                                <sc.InterviewerList>
                                    {formData.requestsInterviewers.map((name, index) =>
                                        <sc.FlexDiv key={index}>
                                            <div style={{ flexGrow: "1" }}>{name}</div>
                                            <sc.Button name={name} key={`remove-${name}`} style={{ "flex-grow": "0", "margin": "0", "font-size": "0.8em" }} onClick={handleRemoveInterviewer}>Remove</sc.Button>
                                        </sc.FlexDiv>
                                    )}
                                </sc.InterviewerList>
                            }
                        </div>
                    </sc.FlexDiv>
                    <hr />
                    <sc.FlexDiv>
                        <sc.FlexDivLabel>Distribution Lists</sc.FlexDivLabel>
                        <sc.IndFilterBox>
                            {form_distribution_lists.map((name, index) =>
                                <sc.ListFilterElement key={index} >
                                    { name }
                                    <sc.FilterInput
                                        name={name}
                                        type="checkbox"
                                        checked={formData && formData.DistributionGroupsRequest && formData.DistributionGroupsRequest.includes(name)}
                                        onChange={handleDistributionCheckbox}
                                    />
                                </sc.ListFilterElement>
                            )}
                        </sc.IndFilterBox>
                    </sc.FlexDiv>
                    <hr />
                    <sc.FlexDiv>
                        <sc.FlexDivLabel>Project Why Statement</sc.FlexDivLabel>
                        <textarea name="projectWhy" type="textarea" placeholder="Share The Why Behind this Project Here..." onChange={handleChange} value={formData.projectWhy || ''} />
                    </sc.FlexDiv>
                    <hr />
                    <sc.FlexDiv>
                        <sc.FlexDivLabel>Request Notes</sc.FlexDivLabel>
                        <textarea name="notes" type="textarea" onChange={handleChange} value={formData.notes || ''} />
                    </sc.FlexDiv>
                    <hr />
                    <sc.FlexDiv>
                        <sc.FlexDivLabel>Role</sc.FlexDivLabel>
                        <select name="RoleId" onChange={handleRoleChange} value={formData.RoleId || ''}>
                            <option value="" default></option>
                            {roles.map(
                                role => {
                                    return (<option key={role.id} value={role.id}>
                                        {role.title}
                                    </option>)
                                }
                            )
                            }
                        </select>
                    </sc.FlexDiv>
                    <sc.FlexDiv>
                        <sc.FlexDivLabel>Override Title</sc.FlexDivLabel>
                        <input name="ProjectTitle" type="text" placeholder={formData.RoleTitle} onChange={handleChange} value={formData.ProjectTitle || ''}></input>
                    </sc.FlexDiv>
                    <sc.JobDescPage>
                        <sc.JobDescTitle>{ formData.ProjectTitle || formData.RoleTitle }</sc.JobDescTitle>
                        <sc.JobDescContent>{ contentJDIntro }</sc.JobDescContent>
                        <sc.JobDescText
                            name="projectSummary"
                            type="textarea"
                            placeholder="Provide a description of the project and the work this role will be performing here."
                            onChange={event => childChangeHandler("JobDescription", event.target.name, event.target.value)}
                            value={formData.JobDescription?.projectSummary || ''}
                        />
                        <sc.JobDescHeader>Responsibilities</sc.JobDescHeader>
                        <sc.JobDescText
                            readOnly
                            style={{ marginBottom: "0" }}
                            form_jd_defaults                            value={formData.JobDescription?.ResponsibilitiesRequired || form_jd_defaults.ResponsibilitiesRequired}
                        />
                        <sc.JobDescText
                            name="ResponsibilitiesOptional"
                            type="textarea"
                            style={{ marginTop: "0" }}
                            onChange={event => childChangeHandler("JobDescription", event.target.name, event.target.value)}
                            value={formData.JobDescription?.ResponsibilitiesOptional || form_jd_defaults.ResponsibilitiesOptional}
                        />
                        <sc.JobDescPageBreak>Page Break</sc.JobDescPageBreak>
                        <sc.JobDescHeader>Required Experience</sc.JobDescHeader>
                        <sc.JobDescText
                            readOnly
                            style={{ marginBottom: "0" }}
                            value={formData.JobDescription?.RequiredExperienceRequired || form_jd_defaults.RequiredExperienceRequired}
                        />
                        <sc.JobDescText
                            name="RequiredExperienceOptional"
                            type="textarea"
                            style={{ marginTop: "0" }}
                            onChange={event => childChangeHandler("JobDescription", event.target.name, event.target.value)}
                            value={formData.JobDescription?.RequiredExperienceOptional || form_jd_defaults.RequiredExperienceOptional}
                        />

                        <sc.JobDescHeader>Preferred Experience</sc.JobDescHeader>
                        <sc.JobDescText
                            readOnly
                            style={{ marginBottom: "0" }}
                            value={formData.JobDescription?.PreferredExperienceRequired || form_jd_defaults.PreferredExperienceRequired}
                        />
                        <sc.JobDescText
                            name="PreferredExperienceOptional"
                            type="textarea"
                            style={{ marginTop: "0" }}
                            onChange={event => childChangeHandler("JobDescription", event.target.name, event.target.value)}
                            value={formData.JobDescription?.PreferredExperienceOptional || form_jd_defaults.PreferredExperienceOptional}
                        />
                        <sc.JobDescPageBreak>Page Break</sc.JobDescPageBreak>
                        <sc.JobDescContentExit>{ contentJDContact }</sc.JobDescContentExit>
                    </sc.JobDescPage>
                    <hr />
                    <sc.ButtonsListFloat style={{ padding: "10px" }}>
                        { !request_id &&
                            <sc.Button style={{ "display": "block", "margin": "5px auto" }} type="button" disabled={submitting} onClick={() => setSubmit(true)}>
                                { request_id ? 'Save' : 'Submit' }
                            </sc.Button>
                        }
                        { request_id && ['Submitted'].includes(formData.State) &&
                            <sc.Button style={{ "display": "block", "margin": "5px auto" }} type="button" disabled={submitting} onClick={() => setSubmit(true)}>
                                Save
                            </sc.Button>
                        }
                        { request_id && ['Submitted'].includes(formData.State) && <div>
                            <hr />
                            <sc.Button style={{ "display": "block", "margin": "5px auto" }} type="button" disabled={submitting} onClick={handleSubmitToRecruit}>
                                Submit to Recruiting
                            </sc.Button>
                        </div> }
                        { request_id && ['Notify Recruiting', 'Recruiting Notified'].includes(formData.State) && <div>
                            <hr />
                            <sc.Button style={{ "display": "block", "margin": "5px auto" }} type="button" disabled={submitting} onClick={handleSubmitToRecruit}>
                                Resubmit to Recruiting
                            </sc.Button>
                        </div> }
                    </sc.ButtonsListFloat>
                </form>
            </div> }
        </sc.Section>
    </>
}
