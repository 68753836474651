import React, { Component } from 'react'
import styled from 'styled-components'
import { fetchWithToken } from '../../utils/fetchWithToken'
import * as sc from '../StyledComponents'
import SettingsNav from './SettingsNav'

export default class SettingsJobDesc extends Component {
    static displayName = SettingsJobDesc.name;
    constructor(props) {
        super(props);
        this.state = {
            Introduction: '',
            ContactStatement: '',
            saveButtonClicked: false,
            cancelButtonClicked: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.saveInformation = this.saveInformation.bind(this);
        this.handleCancelButton = this.handleCancelButton.bind(this);
    }


    componentDidMount() {
        Promise.all([
            fetchWithToken(process.env.REACT_APP_ENDPOINT + '/api/form-setting/content/job-description-introduction'),
            fetchWithToken(process.env.REACT_APP_ENDPOINT + '/api/form-setting/content/job-description-contact-statement')
        ])
            .then(([jdIntroResult, jdContactStateResult]) => {
                this.setState({
                    Introduction: jdIntroResult.data.result.content,
                    ContactStatement: jdContactStateResult.data.result.content,
                    IntroductionCopy: jdIntroResult.data.result.content,
                    ContactStatementCopy: jdContactStateResult.data.result.content
                });
            });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            saveButtonClicked: false,
            cancelButtonClicked: false,
        });
    }

    handleCancelButton(event) {
        this.setState({
            Introduction: this.state.IntroductionCopy,
            ContactStatement: this.state.ContactStatementCopy,
            saveButtonClicked: false,
            cancelButtonClicked: true,
        });
    }

    saveInformation(event) {
        this.setState({
            saveButtonClicked: true,
            cancelButtonClicked: false,
            IntroductionCopy: this.state.Introduction,
            ContactStatementCopy: this.state.ContactStatement,
        });
    }

    render() {
        return <sc.Section>
            <sc.ReturnButton href="/">&lt; Return to Dashboard</sc.ReturnButton>
            <PageItems>
                <SettingsNav />
                <InputBox>
                    <form>
                        <sc.TextList style={{ margin: "0 0 24px 0" }}>
                            <label>
                                Job Description Introduction
                            </label>
                            <InputBoxTextArea
                                placeholder="Enter Into Here..."
                                name="Introduction"
                                type="textarea"
                                wrap="hard"
                                value={this.state.Introduction}
                                onChange={this.handleInputChange} />
                        </sc.TextList>

                        <sc.TextList style={{ margin: "0 0 24px 0" }}>
                            <label>
                                Job Description Contact Statement
                            </label>
                            <InputBoxTextArea
                                placeholder="Enter Contact Statement Here..."
                                name="ContactStatement"
                                type="textarea"
                                wrap="hard"
                                value={this.state.ContactStatement}
                                onChange={this.handleInputChange} />
                        </sc.TextList>
                    </form>

                    <sc.ButtonsList>
                        <sc.Button as="a" onClick={this.handleCancelButton}> Reset </sc.Button>
                        <sc.Button as="a" onClick={this.saveInformation}> Save </sc.Button>
                    </sc.ButtonsList>

                    <div> {(this.state.saveButtonClicked === true) ?
                        <div> <SuccessMsg> Changes Successfully Saved </SuccessMsg></div> : <div> </div>}
                    </div>

                    <div> {(this.state.cancelButtonClicked === true) ?
                        <div> <SuccessMsg> Changes Discarded and Reverted to Original Copy </SuccessMsg></div> : <div> </div>}
                    </div>
                </InputBox>
            </PageItems>
        </sc.Section>
    }
}

const PageItems = styled.div`
    display: flex;
    aligh-items: center;
    margin-bottom: 5%;
    margin-right: 5%;
`;

const SuccessMsg = styled.div`
    color: green;
    text-align: center;
`;

const InputBox = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

const InputBoxTextArea = styled.textarea`
    min-height: 160px;
`;

