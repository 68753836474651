import React, { useEffect, useState } from 'react'
import { fetchWithToken } from '../../utils/fetchWithToken'
import { PDFViewer } from '@react-pdf/renderer'
import JobDescriptionDocument from '../JobDescriptionDocument'

export default function RequestForm(props) {
    const request_id = props.match.params.id

    const [request, setRequest] = useState({})
    const [project, setProject] = useState({})
    const [jd_introduction, setJDIntroduction] = useState('')
    const [jd_contact_statement, setJDContactStatement] = useState('')

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        Promise.all([
            fetchWithToken(process.env.REACT_APP_ENDPOINT + '/api/form-setting/content/job-description-introduction'),
            fetchWithToken(process.env.REACT_APP_ENDPOINT + '/api/form-setting/content/job-description-contact-statement')
        ])
            .then(([result_jd_introduction, result_jd_contact_statement]) => {
                setJDIntroduction(result_jd_introduction.data.result.content)
                setJDContactStatement(result_jd_contact_statement.data.result.content)
            })
            .then(_ => fetchWithToken(`${process.env.REACT_APP_ENDPOINT}/api/requests/${request_id}`))
            .then(result => setRequest(result.data.result))
            .catch(error => console.error(error))
    }, [request_id])

    useEffect(() => {
        if ((request.ProjectCode || null) === null) {
            setLoading(false)
        } else {
            Promise.resolve()
                .then(_ => fetchWithToken(`${process.env.REACT_APP_ENDPOINT}/api/projector-projects/${request.ProjectCode}`))
                .then(result => setProject(result.data.result))
                .then(_ => setLoading(false))
                .catch(error => console.error(error))
        }
    }, [request])

    const [pdf_viewer_style] = useState({
        border: 'none',
        overflow: 'hidden',
        margin: '24px',
        width: 'calc(100vw - 48px)',
        height: 'calc(100vh - 116px)'
    })

    return <>
        { loading
            ? <div>Loading</div>
            : <div style={{ height: '100%', overflow: 'hidden' }}>
                <PDFViewer style={pdf_viewer_style} showToolbar>
                    <JobDescriptionDocument
                        introduction={jd_introduction}
                        contact_statement={jd_contact_statement}
                        request={request}
                        project={project}
                    />
                </PDFViewer>
            </div>
        }
    </>
}
