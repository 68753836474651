import React, { useState, useEffect } from 'react'
import { fetchWithToken } from '../../utils/fetchWithToken'
import * as sc from '../StyledComponents'

export default function RequestForm(props) {
    const domain = process.env.REACT_APP_ENDPOINT
    const request_id = props.match.params.request_id
    const bullhorn_id = props.match.params.bullhorn_id
    const { goBack } = props.history

    const [loading, setLoading] = useState(true)
    const [job_data, setJobData] = useState({})

    useEffect(() => {
        const effect = async () => {
            let request_bullhorn_id = null
            if (request_id) {
                await fetchWithToken(`${domain}/api/requests/${request_id}`).then((result) => {
                    if (!result || !result.data || !result.data.result) {
                        throw new Error(`Cannot load Bullhorn Job for Request ${request_id}`)
                    } else {
                        console.log('Request Loaded', result.data.result)
                        request_bullhorn_id = result.data.result.BullhornID
                    }
                }).catch(err => {
                    console.error(err)
                })
            }

            const load_bullhorn_id = request_bullhorn_id || bullhorn_id;
            const bullhorn_endpoint = `bullhorn/job/${load_bullhorn_id}`

            await fetchWithToken(`${domain}/${bullhorn_endpoint}`).then((result) => {
                console.log('Bullhorn Job Loaded', result.data.data)
                setJobData(result.data.data)
            }).catch(err => {
                console.error(err)
            })

            setLoading(false)
        }

        effect()

    // We need this to run once and just once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <sc.Section>
                <sc.ButtonsList>
                    <sc.ReturnButton as="a" href="/bullhorn">&lt; Return to Bullhorn Lookup</sc.ReturnButton>
                </sc.ButtonsList>
                <sc.ButtonsList>
                    { request_id && <sc.ReturnButton as="a" href={`/requests/edit/${request_id}`}>&lt; Return to Request ${request_id}</sc.ReturnButton> }
                </sc.ButtonsList>
                { loading ? <sc.LoadingDiv>Loading Content</sc.LoadingDiv> : <div>
                    <div style={{ textAlign: 'Center'}}>
                        <h1>Bullhorn JobOrder #{ job_data.id }</h1>
                        <div style={{ margin: '4px 24px', padding: '4px', fontWeight: 'bold', fontSize: '2em', color: 'white', background: job_data.isOpen ? 'green' : 'grey' }}>
                            { job_data.isOpen ? 'OPEN' : 'CLOSED' }
                        </div>
                    </div>
                    <hr />
                    <div
                        style={{ margin: '12px 48px', padding: '12px', background: 'white', border: '1px solid lightgrey' }}
                        dangerouslySetInnerHTML={{ __html: job_data?.description || '' }}
                    />
                    <hr />
                    <div style={{ height: "60px" }} />
                    <sc.ButtonsListFloat style={{ padding: "10px" }}>
                        <sc.Button style={{ "display": "block", "margin": "5px auto" }} type="button" onClick={() => goBack()}>
                            Back
                        </sc.Button>
                    </sc.ButtonsListFloat>
                </div> }
            </sc.Section>
        </>
    )
}
