import React, { Component } from 'react';
import { RequestRow } from './RequestRow';

export class RequestList extends Component {
    render() {
        return <table className="RequestList">
            <tbody>
                {this.props.requests.map((request) =>
                    (request.id == null) || <RequestRow key={request.id} request={request}>{request.id}</RequestRow>
                )}
            </tbody>
        </table>
    }
}