import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { debounce } from 'lodash'
import styled from 'styled-components'
import { Autocomplete, TextField } from '@mui/material'
import { fetchWithToken } from '../utils/fetchWithToken'

export default function Auto(props) {
    const [inputValue, setInputValue] = useState('')
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState([])

    const [valued, setValued] = useState(props.valued || false)
    useEffect(() => { setValued(props.valued) }, [props.valued])

    const [value, setValue] = useState(props.value || '')
    useEffect(() => { setValue(props.value) }, [props.value])

    const load = useMemo(() => debounce((value) => {
        if ((value || '').length < 1) {
            setOptions([])
            return
        }

        return fetchWithToken(`${process.env.REACT_APP_ENDPOINT}/api/search-active-directory/${value}`)
            .then((response) => {
                const options = (response.data || []).map(result => {
                    return { label: result.name || '', id: result.email || '' }
                })

                if ((options || null) !== null) {
                    setOptions(options)
                    setOpen(true)
                }
            }).catch(err => {
                console.error(err)
            })
    }, 200), [])

    const add = useCallback((value) => {
        setInputValue('')
        setOptions([])

        load.cancel()

        if (!value) {
            return
        }

        props.add(value)
    }, [props, load])

    return (
        <>
            { !valued || <ShowValue>{ value }</ShowValue> }
            <Autocomplete
                disablePortal
                disableClearable
                blurOnSelect
                clearOnBlur
                style={{ flexGrow: "1" }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={inputValue}
                inputValue={inputValue}
                onInputChange={(event, value) => {
                    setInputValue(value)
                    load(value)
                }}
                onChange={(event, value) => { add(value) }}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                open={open}
                options={options}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Search for Launch Employee"
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            style: (valued ? { borderRadius: '0 3px 3px 0' } : null)
                        }}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                event.defaultMuiPrevented = true
                                event.preventDefault()
                                return false
                            }
                        }}
                    />
                    )}
            />
        </>
    )
}

const ShowValue = styled.div`
    display: table-cell;
    vertical-align: middle;
    white-space: nowrap;
    background: white;
    border-radius: 3px 0 0 3px;
    border: 1px solid black;
    overflow: hidden;
    padding: 6px;
    line-height: 42px;
    width: 40%;
`
