import React from 'react'
import './App.css'
import NavMenu from './components/NavMenu'
import Home from './components/Home'
import Settings from './components/Settings/Settings'
import SettingsJobDesc from './components/Settings/SettingsJobDesc'
import SettingsAccess from './components/Settings/SettingsAccess'
import SettingsAccessGroup from './components/Settings/SettingsAccessGroup'
import SettingsAccessUser from './components/Settings/SettingsAccessUser'
import Requests from './components/Request/Requests'
import RequestForm from './components/Request/RequestForm'
import RequestView from './components/Request/RequestView'
import RequestBullhorn from './components/Request/RequestBullhorn'
import Roles from './components/Roles/Roles'
import RoleForm from './components/Roles/RoleForm'
import Bullhorn from './components/Bullhorn'
import { MsalProvider } from '@azure/msal-react'
import { EventType } from '@azure/msal-browser'
import { authentication } from './auth'
import { Route, Switch } from 'react-router-dom'

const accounts = authentication.getAllAccounts()

if (accounts.length > 0) {
    authentication.setActiveAccount(accounts[0])
}

authentication.addEventCallback(
    (event) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
            authentication.setActiveAccount(event.payload.account)
        }
    },
    (error) =>{
        console.error('error', error)
    }
)

authentication.handleRedirectPromise()
    .then(() => {
        const account = authentication.getActiveAccount()
        if(!account){
            authentication.loginRedirect()
        }
    })
    .catch((err) => console.error(err))

function App() {
    return (
        <MsalProvider instance={authentication}>
            <NavMenu />
            <Pages />
        </MsalProvider>
  )
}

function Pages() {
    return <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route exact path="/requests" component={Requests}></Route>
        <Route exact path="/requests/!/:command" component={Requests}></Route>
        <Route exact path="/requests/!/:command/page/:page" component={Requests}></Route>
        <Route exact path="/requests/!/:command/page/:page/:results" component={Requests}></Route>
        <Route exact path="/requests/page/:page" component={Requests}></Route>
        <Route exact path="/requests/page/:page/:results" component={Requests}></Route>
        <Route exact path="/requests/create" component={RequestForm}></Route>
        <Route exact path="/requests/edit/:id" component={RequestForm}></Route>
        <Route exact path="/requests/job-description/:id" component={RequestView}></Route>
        <Route exact path="/requests/bullhorn/:request_id" component={RequestBullhorn}></Route>

        <Route exact path="/roles" component={Roles}></Route>
        <Route exact path="/roles/!/:command" component={Roles}></Route>
        <Route exact path="/roles/!/:command/page/:page" component={Roles}></Route>
        <Route exact path="/roles/!/:command/page/:page/:results" component={Roles}></Route>
        <Route exact path="/roles/page/:page" component={Roles}></Route>
        <Route exact path="/roles/page/:page/:results" component={Roles}></Route>
        <Route exact path="/role/create" component={RoleForm}></Route>
        <Route exact path="/role/create/:copy_id" component={RoleForm}></Route>
        <Route exact path="/role/edit/:id" component={RoleForm}></Route>

        <Route exact path="/bullhorn" component={Bullhorn}></Route>
        <Route exact path="/bullhorn/:bullhorn_id" component={RequestBullhorn}></Route>

        <Route exact path="/settings" component={Settings}></Route>
        <Route exact path="/settings/job-descriptions" component={SettingsJobDesc}></Route>
        <Route exact path="/settings/access" component={SettingsAccess}></Route>
        <Route exact path="/settings/access/group/:id" component={SettingsAccessGroup}></Route>
        <Route exact path="/settings/access/user/:email" component={SettingsAccessUser}></Route>
    </Switch>
}

export default App
