/* The Settings page is linked in the navigation bar and is meant to expand over time as new needs for settings arise
 * Dropdown contains the sidebar with sub-settings
 * this.state is managing the true/false value when a user selects a sub-setting from the menu bar
 * When true, the corresponding react component will be rendered
 *
 * When adding a new section of the Settings page:
 * Add a new this.state.newvalueClicked and set it to false
 * Add a new handler method
 * Use this.setState() to change this.state.newvalueClicked to true
 * Set the other sub-setting component's this.state.values to false to prevent them from rendering
 * Add new DropDownItem with an onClick call to the newly created method
 * Add a new div to check the boolean value of this.state.newvalue and render the corresponding component when true
 * Now create a new component .js file for the new sub-setting to be rendered
 * */

import React, { Component } from 'react'
import styled from 'styled-components'
import SettingsNav from './SettingsNav'
import { fetchWithToken } from '../../utils/fetchWithToken'
import * as sc from '../StyledComponents'
import Auto from '../Auto'
import { TextField } from '@mui/material'

export default class SettingsAccess extends Component {
    constructor(props) {
        super(props)

        this.map_users = new Map()
        this.map_roles = new Map()
        this.map_groups = new Map()
        this.map_group_roles = new Map()
        this.map_group_members = new Map()

        this.state = {
            newGroupName: '',
            appRoles: null,
            appUserGroups: null,
            appUserGroupRole: null,
            loading: true,
            error: false,
            error_message: ''
        }
    }

    async deleteAppUserGroupMember (id) {
        await fetchWithToken(
            process.env.REACT_APP_ENDPOINT + `/api/AppUserGroupMember/${id}`,
            { method: "DELETE" }
        )
    }

    componentDidMount() {
        Promise.all([
          fetchWithToken(process.env.REACT_APP_ENDPOINT + "/api/AppRole"),
          fetchWithToken(process.env.REACT_APP_ENDPOINT + "/api/AppUserGroup"),
          fetchWithToken(process.env.REACT_APP_ENDPOINT + "/api/AppUserGroupRole"),
          fetchWithToken(process.env.REACT_APP_ENDPOINT + "/api/AppUserGroupMember"),
        ]).then(([appRoles, appUserGroups, appUserGroupRole, appUserGroupMember]) => {
            appRoles.data.forEach(element => {
                this.map_roles.set(element.id, element.value)
            });

            appUserGroups.data.forEach(element => {
                this.map_groups.set(element.id, element.name)
                this.map_group_roles.set(element.id, [])
                this.map_group_members.set(element.id, [])
            });

            appUserGroupRole.data.forEach(element => {
                let list = this.map_group_roles.get(element.appUserGroupId)
                if (!list) {
                    return
                }

                list.push(element)
                this.map_group_roles.set(element.appUserGroupId, list)
            });

            appUserGroupMember.data.forEach(element => {
                let list = this.map_group_members.get(element.appUserGroupId)
                if (!list) {
                    return
                }

                list.push(element)
                this.map_group_members.set(element.appUserGroupId, list)
            })

            this.setState({
                appRoles: appRoles.data,
                appUserGroups: appUserGroups.data,
                appUserGroupRole: appUserGroupRole.data,
                appUserGroupMember: appUserGroupMember.data,
                loading: false
            })
        }).catch((error) => {
            this.setState({
                loading: false,
                error: true,
                error_message: String(error)
            })
        })
    }

    handleOpenUser (user) {
        window.location = `/settings/access/user/${user.id}`
    }

    handleAddGroupName (value) {
        this.setState({ newGroupName: value })
    }

    async handleAddGroup () {
        if (!window.confirm(`Create a new group titled "${this.state.newGroupName}"?`)) {
            return
        }

        let options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: { name: this.state.newGroupName }
        }

        fetchWithToken(`${process.env.REACT_APP_ENDPOINT}/api/AppUserGroup`, options)
            .then(_ => { window.location.reload(true) })
    }

    render() {
        return <sc.Section>
            <sc.ReturnButton href="/">&lt; Return to Dashboard</sc.ReturnButton>
            <PageItems>
                <SettingsNav />
                <Page>
                    { this.state.loading ? <BannerMessage>Loading Access Settings</BannerMessage> : undefined }
                    { this.state.error ? <ErrorMessage>{ this.state.error_message }</ErrorMessage> : undefined }

                    { (this.state.loading || this.state.error) || <>
                        <h3>Select User</h3>
                        <sc.FlexDiv>
                            <Auto add={this.handleOpenUser} />
                        </sc.FlexDiv>
                        <hr style={{ margin: "24px 0" }} />
                        <sc.FlexDiv style={{ paddingBottom: "12px"}}>
                            <TextField
                                style={{ flexGrow: "1"}}
                                value={this.state.newGroupName}
                                onChange={event => this.handleAddGroupName(event.target.value)}
                            />
                            <sc.Button
                                style={{ margin: "0 0 0 12px", height: "56px", flexGrow: "0"}}
                                disabled={String(this.state.newGroupName).trim().length < 1}
                                onClick={() => this.handleAddGroup()}
                            >
                                Create Group
                            </sc.Button>
                        </sc.FlexDiv>
                        <table style={{ width: "100%" }}>
                        <thead>
                            <th>Group</th>
                            <th colSpan={2}>Access</th>
                        </thead>
                        <tbody>
                            { this.state.appUserGroups.map((group) =>
                                <>
                                    <tr style={{ marginTop: "12px", borderBottom: "1px solid lightgrey", background: "rgba(255, 255, 255, 0.6)" }}>
                                        <td style={{ fontSize: "1.3em", paddingRight: "12px" }}>{ group.name }</td>
                                        <td colSpan={2}>
                                            { this.map_group_roles.get(group.id).map((role) =>
                                                <AccessTag>{ this.map_roles.get(role.appRoleId) }</AccessTag>
                                            ) }
                                        </td>
                                        <td>
                                            <button style={{ fontSize: "0.8em" }} onClick={() => { window.location = `/settings/access/group/${group.id}` }}>Edit</button>
                                        </td>
                                    </tr>
                                    { this.map_group_members.get(group.id).map((member) =>
                                    <tr className="highlight" key={member.id}>
                                        <td></td>
                                        <td colSpan={3}>
                                            { member.appUserEmail || <small style={{ color: "grey" }}>Unknown User ({ member.appUserEmail || 'undefined' })</small> }
                                        </td>
                                    </tr>
                                    ) }
                                    <tr style={{ borderTop: "1px solid lightgrey", marginBottom: "24px" }}>&nbsp;<td></td></tr>
                                </>
                            ) }
                        </tbody>
                        </table>
                    </> }
                </Page>
            </PageItems>
        </sc.Section>
    }
}

const PageItems = styled.div`
    display: flex;
    aligh-items: center;
    margin-bottom: 5%;
    margin-right: 5%;
`;

const Page = styled.div`
    flex-grow: 1;
`;

const BannerMessage = styled.div`
    text-align: center;
    font-color: grey;
    font-decoration: italic;
`;

const ErrorMessage = styled.div`
    text-align: center;
    font-color: red;
    font-decoration: italic;
`;

const AccessTag = styled.div`
    display: inline-block;
    margin-right: 6px;
    background: darkgrey;
    color: white;
    font-size: 0.8em;
    padding: 0 3px;
`
