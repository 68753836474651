import { authentication } from '../auth'
import axios from 'axios';

export async function addToken(options) {
    const update = { ...options }

    if (authentication) {
        const account = authentication.getActiveAccount()
        if (!account) {
            return
        }

        const response = await authentication.acquireTokenSilent({ scopes: [process.env.REACT_APP_MSAL_SCOPE], account })

        update.headers = {
            ...update.headers,
            Authorization: `Bearer ${response.accessToken}`,
        }
    }

    return update
}

export const fetchWithToken = async (url, options) => axios(url, await addToken(options))
