import React, { useState, useCallback } from 'react'
import { TextField } from '@mui/material'
import * as sc from './StyledComponents'


export default function RequestForm() {
    const [lookupBullhorn, setLookupBullhorn] = useState("")
    const [lookupResourceRequest, setLookupResourceRequest] = useState("")

    const handleLookupBullhorn = useCallback(() => {
        const encoded_value = encodeURIComponent(lookupBullhorn)
        window.location = `/bullhorn/${encoded_value}`
    }, [lookupBullhorn])

    const keyPressLookupBullhorn = useCallback(event => {
        if (event.keyCode === 13) {
            handleLookupBullhorn()
        }
    }, [handleLookupBullhorn])

    const handleLookupResourceRequest = useCallback(() => {
        const encoded_value = encodeURIComponent(lookupResourceRequest)
        window.location = `/requests/bullhorn/${encoded_value}`
    }, [lookupResourceRequest])

    const keyPressLookupResourceRequest = useCallback(event => {
        if (event.keyCode === 13) {
            handleLookupResourceRequest()
        }
    }, [handleLookupResourceRequest])

    return <>
        <sc.FlexDiv style={{ margin: "12px 24px" }}>
            <TextField
                placeholder="Bullhorn JobOrder ID"
                style={{ flexGrow: "1" }}
                value={lookupBullhorn} onChange={(e) => setLookupBullhorn(e.target.value)}
                onKeyDown={keyPressLookupBullhorn}
            />
            <sc.Button
                style={{ margin: "0 0 0 12px", height: "56px", width: "100px" }}
                onClick={handleLookupBullhorn}
            >
                Fetch
            </sc.Button>
        </sc.FlexDiv>
        <sc.FlexDiv style={{ margin: "24px 24px 12px" }}>
            <TextField
                placeholder="Resource Request ID"
                style={{ flexGrow: "1" }}
                value={lookupResourceRequest}
                onChange={(e) => setLookupResourceRequest(e.target.value)}
                onKeyDown={keyPressLookupResourceRequest}
            />
            <sc.Button
                style={{ margin: "0 0 0 12px", height: "56px", width: "100px" }}
                onClick={handleLookupResourceRequest}
            >
                Fetch
            </sc.Button>
        </sc.FlexDiv>
    </>
}
