import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class RolesRow extends Component {
    static displayName = RolesRow.name;
    constructor(props) {
        super(props)
        this.state = {
            hide: false,
        }
    }
    /* r.RoleID may cause an issue because the naming was originally "number" and was updated. */
    render() {
        const r = this.props.roles
        const name = r.id
        return (
            // TECHDEBT page should update to hide deleted items, instead of manual hiding
            !this.state.hide &&
            <tr className='RoleListItem'>
                <td>{r.RoleTitle}</td>
                <td>{r.FunctionalArea} <small>{r.Discipline ? r.Discipline : ''}</small></td>
                <td>{r.RoleID}</td>
                <td>
                    <Link to={`/role/edit/${name}`}>
                        <button className='actionButton' id='settingsButton' type="button">Edit</button>
                    </Link>
                </td>
                <td>
                    <Link to={`/role/create/${name}`}>
                        <button className='actionButton' id='settingsButton' type="button">Copy</button>
                    </Link>
                </td>
            </tr>
        );
    }
}